<template>
    <CommonHeader />
    <div id="main-content">
        <div id="page-container">
            <!--**************************common**************-->
            <!--*************alert-->
            <div :class="alertClass" id="hidemessage" v-if="message">
                <p class="txt">{{ alertMessage }}</p>
                <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
            </div>
            <!--*************alert-->
            <div class="my_profile">
                <h4>My Profile <span v-on:click="performEdit"
                        style="cursor: pointer; float: right; border: none; padding-right: 3%; margin-top: 1%; font-size: 18px; color: gray;">Edit
                        <i class="fa fa-edit" aria-hidden="true"></i></span></h4>
                <form v-on:submit.prevent="updateProfile" method="post" enctype="multipart/form-data">
                    <div class="profile_p">
                        <input type="file" name="company_logo" id="fileUpload" v-on:change="onFileChange"
                            accept=".jpg,.jpeg" style="width:100px; height:100px;" />
                        <div class="parent_upload">
                            <div class="uploadProfileImage" title=" Browse Profile Image">
                                <img :src="imageUrl" alt="" class="avtar1 img-responsive"
                                    style="width:100px; height:100px;">
                                <!-- <input type="file" name="company_logo" id="fileUpload"  ref="file" required="" v-on:change="onFileChange" accept=".jpg,.jpeg"  style="width:100px; height:100px;" /> -->
                            </div>
                            <span class="cross" v-on:click="profileDelete"><i class="fa fa-times"
                                    aria-hidden="true"></i></span>
                        </div>
                    </div>
                    <div class="my_pro" id="opa">
                        <label>Name</label><br>
                        <input type="text" id="name" required v-model="name" readonly>
                    </div>
                    <div class="my_pro">
                        <label>Firm Name</label><br>
                        <input type="text" id="firm_name" required v-model="firm_name" readonly>
                    </div>
                    <div class="my_pro">
                        <label>Email</label><br>
                        <input type="text" id="email" required v-model="email" readonly>
                    </div>
                    <div class="my_pro">
                        <label>Mobile</label><br>
                        <input type="text" id="mobile" required v-model="mobile" readonly>
                    </div>
                    <div class="my_pro">
                        <label>Membership Number</label><br>
                        <input type="text" id="membership_no" v-model="membership_no" required readonly>
                    </div>
                    <div class="my_pro">
                        <label>Professional Category</label><br>
                        <select style="height:49px;" v-model="professional_category" required>
                            <option value="CA">CA</option>
                            <option value="CS">CS</option>
                            <option value="Account Profession">Account Profession</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div class="my_pro">
                        <label>Bank Name</label>
                        <input type="text" id="bankName" required v-model="bankName" readonly>
                    </div>
                    <div class="my_pro">
                        <label>Account Number</label>
                        <input type="text" id="accountNumber" required v-model="accountNumber" readonly>
                    </div>
                    <div class="my_pro">
                        <label>Ifsc Code</label>
                        <input type="text" id="ifscCode" required v-model="ifscCode" readonly>
                    </div>
                    <!-- <div class="my_pro">
                    <label>Password</label><br>
                    <input v-if="showPassword" type="text" class="input" v-model="password" />
                    <input v-else type="password" class="input" v-model="password">
                    <span class="icon is-small is-right" @click="toggleShow">
                        <i class="fa" :class="{ 'fa-eye-slash': showPassword, 'fa-eye': !showPassword }"></i>
                    </span>
                </div>
                <div class="my_pro">
                    <label>Re-Enter Password</label><br>
                    <input type="password" name="re-password" required>
                </div> -->
                    <button v-if="btn" class="profile_sub" type="submit">Update
                        <img src="https://instabill.co/public/front/images/loader.gif" class="load_show" v-if="loader"
                            style="width: 15px;" />
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import CommonHeader from './CommonHeader.vue';
import { postApi } from "../assets/js/helper.js";
import axios from 'axios';
export default {
    name: 'MyProfile',
    components: {
        CommonHeader
    },
    data() {
        return {
            professional_category: "",
            name: "",
            email: "",
            mobile: "",
            firm_name: "",
            membership_no: "",
            bankName: "",
            accountNumber: "",
            ifscCode: "",
            btn: false,
            loader: false,
            message: false,
            alertMessage: "",
            alertClass: "",
            imageUrl: "https://instabill.co/public/images/profile_av.png"
        };
    },
    created() {
        this.getCaProfile();
    },
    computed: {
    },
    methods: {
        onFileChange(e) {

            const file = e.target.files[0];
            this.imageUrl = URL.createObjectURL(file);
            var id = localStorage.getItem('admin_id');
            let formData = new FormData();
            formData.append('id', id);
            formData.append('company_logo', file);
            formData.append('header', true);

            axios.post("https://instabill.co/api/update_ca_profile_logo", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                console.log(response);
            });



        },

        profileDelete() {
            this.imageUrl = "https://instabilldoc.s3.ap-south-1.amazonaws.com/profile_pic/phpwpeohU";
            var id = localStorage.getItem('admin_id');
            let formData = new FormData();
            formData.append('id', id);
            formData.append('header', true);

            axios.post("https://instabill.co/api/update_ca_profile_logo", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            }).then((response) => {
                console.log(response);
            });

        },
        async getCaProfile() {
            var id = localStorage.getItem('admin_id');
            const response = await postApi(
                "/get_ca_profile",
                {
                    id: id,
                },
                {}
            );
            try {
                if (response.errorCode == "0") {
                    this.name = response.data.name;
                    this.email = response.data.email;
                    this.mobile = response.data.phone;
                    this.firm_name = response.data.firm_name;
                    this.membership_no = response.data.membership_number;
                    this.bankName = response.data.bankName;
                    this.accountNumber = response.data.accountNumber;
                    this.ifscCode = response.data.ifscCode;
                    this.professional_category = response.data.professional_category;
                    if (response.data.logo != "") {
                        this.imageUrl = "https://instabilldoc.s3.ap-south-1.amazonaws.com/profile_pic/" + response.data.logo;
                    } else {
                        this.imageUrl = "https://instabill.co/public/images/profile_av.png";
                    }
                    return false;
                }
            } catch (err) {
                alert(err);
            }

        },

        performEdit() {
            document.getElementById('name').removeAttribute('readonly');
            document.getElementById('email').removeAttribute('readonly');
            document.getElementById('mobile').removeAttribute('readonly');
            document.getElementById('firm_name').removeAttribute('readonly');
            document.getElementById('membership_no').removeAttribute('readonly');
            document.getElementById('bankName').removeAttribute('readonly');
            document.getElementById('accountNumber').removeAttribute('readonly');
            document.getElementById('ifscCode').removeAttribute('readonly');
            document.getElementById("name").focus();


            // document.getElementsByClassName("my_pro").style.opacity = 1;

            var my_pro = document.querySelectorAll(".my_pro");
            for (var i = 0; i < my_pro.length; i++) {
                console.log(my_pro[i]);
                my_pro[i].classList.add(['opacity1']);
            }

            this.btn = !this.btn;

        },

        async updateProfile() {

            var id = localStorage.getItem('admin_id');
            var name = this.name;
            var email = this.email;
            var mobile = this.mobile;
            var firm_name = this.firm_name;
            var membership_no = this.membership_no;
            var professional_category = this.professional_category;

            this.loader = true;
            this.alertMessage = "Your profile has been successfull updated.";
            this.alertClass = "success";
            this.message = true;
            setTimeout(() => this.message = false, 3000);
            // alert(professional_category+" "+name+" "+email+" "+mobile+" "+firm_name+" "+membership_no);

            const response = await postApi(
                "/update_ca_profile",
                {
                    id: id,
                    name: name,
                    email: email,
                    phone: mobile,
                    firm_name: firm_name,
                    membership_number: membership_no,
                    professional_category: professional_category,
                    bankName: this.bankName,
                    accountNumber: this.accountNumber,
                    ifscCode: this.ifscCode,
                },

                {}
            );
            try {
                if (response.errorCode == "0") {
                    this.$router.push("/Dashboard");

                    return false;

                }
            } catch (err) {
                alert(err);
            }

        },
        close_msg() {
            this.message = false;
        },


    }

}
</script>
<style>
.my_pro.opacity1 input {
    background: #fff;
}

.my_pro.opacity1 select {
    background: #fff;
}

.success {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #ffae00;
    transition: 1s;
}

p.txt {
    width: 91%;
    float: left;
    margin: 0;
}

.my_profile .my_pro {
    opacity: 0.5;
}

.opacity1 {
    opacity: 1 !important;
}

.error_msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}

.uploadProfileImage {
    background: rgba(170, 201, 177, .7);
    height: 105px;
    width: 105px;
    background-size: cover;
    /* border-radius: 50%; */
    z-index: 2;
    overflow: hidden;
    border: 2px dashed #fff !important;
    cursor: pointer;
    border-radius: 86px;
}

.parent_upload {
    position: relative;
    width: 100px;
    height: 100px;
}

span.cross i {
    font-size: 12px !important;
}

.cross {
    color: #fff;
    font-weight: 400;
    top: 3px;
    right: 0%;
    position: absolute;
    background: linear-gradient(85deg, #f66a59, #ffae00);
    width: 20px;
    height: 20px;
    font-size: 8px;
    text-align: center;
    line-height: 21px;
    border-radius: 50px;
    z-index: 99999999;
}

.profile_p {
    position: relative;
    height: 130px;
    margin-top: 5%;
}

input#fileUpload {
    position: absolute;
    opacity: 0;
    z-index: 999999;
}
</style>
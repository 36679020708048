<template>
    <CommonHeader />
    <div id="main-content">
        <div id="page-container">
            <!--**************************common**************-->
            <div class="table-responsive">
                <hr>
                 <form v-on:submit.prevent="excel_import" method="post">
                    
                <table class="table table-borderless" align="center" style=" width: 50%;margin: auto;">
                   <tbody>
                        <tr>
                            <th>
                                <h4>Fields</h4>
                            </th>
                            <th>
                                <h4>Excel Column</h4>
                            </th>
                        </tr>
    
                       
    
                        <input type="hidden" name="_token" value="5dfbRyc0wvMj3647MFEpgGzh9URQcYbl6XTjJiOc">
    
    
                        <tr>
                            <td>Debit Note No. <span style="color:#ff0000;">*</span> </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="invoice_no"  required=""  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
                        
                        <tr>
                            <td>Date (DD-MM-YYYY) <span style="color:#ff0000;">*</span> </td>
                            <td class="pos">
    
                                <select class="custom-select1" required="" v-model="invoice_date" style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                    <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
    
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                               
                                <p class="info_d">Map Date of Transaction</p>
                            </td>
                        </tr>
    
    
                        <tr>
                            <td></td>
                            <td>
                                <p style="color:red;margin-top: -13px;  margin-left:5px;  margin-bottom: -13px;font-size: 11px;"> Date format should be DD-MM-YYYY</p>
                            </td>
                        </tr>
                        <tr>
                            <td>Vendor  Name <span style="color:#ff0000;">*</span> </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="vendor_name"  required=""  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
                        <tr>
                            <td>Vendor  Address </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="vendor_address"   style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
                      
                        <tr>
                            <td>Vendor  GSTIN </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="vendor_gstin"    style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
    
                        <tr>
                            <td>Place of Supply </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="place_of_supply"    style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
                        <tr>
                            <td>Product Name <span style="color:#ff0000;">*</span> </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="product_name"  required=""  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
    
                        <tr>
                            <td>Product Description  </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="product_description"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
                   
    
                        
                        <tr>
                            <td>Unit as measure </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="unit_as_measure"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
                        <tr>
                            <td>HSN/SAC </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="hsn_sac"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
    
                        <tr>
                            <td>Product Rate<span style="color:#ff0000;">*</span> </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="product_rate"  required=""  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
    
                        <tr>
                            <td>Quantity </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="quantity"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
                        
                        <tr>
                            <td>Discount </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="discount"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
                        
    
    
                        <tr>
                            <td>Taxable Value<span style="color:#ff0000;">*</span> </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="taxable_value"  required=""  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    

                        <tr>
                            <td>Tax Rate(%)  </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="tax_rate"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>


                        <tr>
                            <td>CGST  </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="cgst"    style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>

                        <tr>
                            <td>SGST  </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="sgst"    style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>

                        <tr>
                            <td>IGST  </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="igst"   style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>

                        <tr>
                            <td>Cess  </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="cess"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                        {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>

                        <tr>
                            <td>Shipping Charge </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="shipping_charge"  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
    
    
                        <tr>
                            <td>Total Amount<span style="color:#ff0000;">*</span> </td>
                            <td class="pos">
    
                                <select class="custom-select1" v-model="total_amount"  required=""  style="display:inline-block;float:left;" >
                                    <option class="custom-select1" value="">Yet to map </option>
                                     <option v-for="header in headers" :key="header">
                                    {{header}}
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down ch" aria-hidden="true"></i>
                                </td>
                        </tr>
                       
                        <tr>
                            <td colspan="2" align="right">
                                <button type="submit" name="myButton" class="btn btn-success orange" style="background-color:orange; margin-right: 10px;"> Map Now  
                                    <img src="https://instabill.co/public/front/images/loader.gif"
                        class="load_show"
                        v-if="loader"
                        style="width: 15px;"
                      />
                                </button>
                               
                                <a href="" v-on:click="backtoview"> <input type="button" name="" value="x" class="btn btn-primary black cls_b"></a></td>
                        </tr>
                    
                    </tbody>
                </table>
                </form>
            </div>
    
        </div>
    </div>
    </template>
    
    <script>
    import CommonHeader from './CommonHeader.vue';
    import { postApi } from "../assets/js/helper.js";
    export default {
        name: 'ImportSales',
        components: {
            CommonHeader
        },
        props:['user_id','company_id','csv_db_id'],
        created() {
    this.headers= JSON.parse(localStorage.getItem("headers"));
        },
        data() {
            return{
        headers:[],
        invoice_no:"",
invoice_date:"",
vendor_name:"",
vendor_address:"",
vendor_gstin:"",
place_of_supply:"",
product_name:"",
product_description:"",
unit_as_measure:"",
hsn_sac:"",
product_rate:"",
quantity:"",
discount:"",
taxable_value:"",
tax_rate:"",
cgst:"",
sgst:"",
igst:"",
cess:"",
shipping_charge:"",
total_amount:"",
        loader:false,
            }
        },
        methods: {
    async excel_import(){
        
        this.loader = true;
  
        const response = await postApi(
          "/purchasereturn_upload",
          {
            user_id: this.user_id,
           company_id: this.company_id,
           csv_data_file_id:this.csv_db_id,
           invoice_no: this.invoice_no,
           invoice_date : this.invoice_date,
           vendor_name : this.vendor_name,
           vendor_address : this.vendor_address,
           pincode : this.pincode,
           vendor_gstin:this.vendor_gstin,
           place_of_supply:this.place_of_supply,
           product_name : this.product_name,
           product_description : this.product_description,
           unit_as_measure : this.unit_as_measure,
           hsn_sac : this.hsn_sac,
           product_rate : this.product_rate,
           quantity : this.quantity,
           discount : this.discount,
           taxable_value:this.taxable_value,
           tax_rate:this.tax_rate,
           cgst:this.cgst,
           sgst:this.sgst,
           igst:this.igst,
           cess:this.cess,
           shipping_charge:this.shipping_charge,
           total_amount:this.total_amount
          },
          {}
        );
        try {
          if (response.errorCode == "0") {         
   
    alert("Import Successfully");
    this.$router.push({ 
    name: 'ViewDetails', 
    params:{ 
    user_id:this.user_id,
    company_id:this.company_id,
    } 
    });
    return false;
          } 
    else
    {
      alert("Something Went Wrong");
    }
    
        } catch (err) {
          alert(err);
        }
    
          },

          backtoview(){
            this.$router.push({ 
    name: 'ViewDetails', 
    params:{ 
    user_id:this.user_id,
    company_id:this.company_id,
    } 
    });
          }
    
    }
    
        }
    
  
    </script>
    
    <style>
    tr {
        border-bottom: 0px solid #dee2e6 !important;
    }
    
    .pos {
        position: relative
    }
    
    .ch {
        position: absolute;
        right: 8%;
        top: 38%;
        font-size: 10px;
        cursor: pointer
    }
    
    .switcher2 {
        position: relative;
        width: 60px;
        height: 25px;
        display: inline-block;
        font-family: 'Oswald', sans-serif;
        float: right;
    }
    
    .switcher2 input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: relative;
        width: 100%;
        height: 25px;
        border-radius: 50px !important;
        /* border:1px solid black; */
        background-color: white;
        outline: none;
        border-bottom: none;
        cursor: pointer;
        font-family: 'Oswald', sans-serif;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1) !important;
    }
    
    .switcher2 input:before,
    body span.switcher2 input:after {
        z-index: 2;
        position: absolute;
        top: 52%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        color: black;
    }
    
    .switcher2 input:before {
        content: 'YES';
        font-size: 11px;
        left: 7px;
        top: 13px;
        font-family: 'Oswald', sans-serif;
    }
    
    .switcher2 input:after {
        content: 'NO';
        font-size: 11px;
        right: 7px;
        top: 14px;
        font-family: 'Oswald', sans-serif;
    
    }
    
    .switcher2 label {
        z-index: 1;
        position: absolute;
        top: 10px;
        bottom: 10px;
        border-radius: 50px;
    }
    
    .switcher2.switcher-1 input {
        transition: .25s -.1s;
    }
    
    /* body span.switcher.switcher-1 input:checked {
      background-color: #FFFFFF;
    } */
    .switcher2.switcher-1 input:checked:before {
        color: #FFFFFF;
        font-weight: 600;
        transition: color .5s .2s;
    }
    
    .switcher2.switcher-1 input:checked:after {
        color: black;
        font-weight: 600;
        transition: color .5s;
    }
    
    .switcher2.switcher-1 input:checked+label {
        bottom: -5px;
        left: 2px;
        right: 30px;
        top: 2px;
        background: linear-gradient(85deg, #f66a59, #ffae00);
        transition: left .1s, right .1s .2s;
    }
    
    .switcher2.switcher-1 input:not(:checked) {
        background: white;
        transition: background .5s -.1s;
    }
    
    .switcher2.switcher-1 input:not(:checked):before {
        color: black;
        font-weight: 600;
        transition: color .5s;
    }
    
    .switcher2.switcher-1 input:not(:checked):after {
        color: White;
        font-weight: 600;
        transition: color .5s .2s;
    }
    
    .switcher2.switcher-1 input:not(:checked)+label {
        left: 32px;
        right: 3px;
        top: 3px;
        bottom: -6px;
        background: linear-gradient(85deg, #ffae00, #f66a59);
        transition: left .4s .2s, right .1s, background .35s -.1s;
    }
    td a input {
        margin-top: 11px;
        border-radius: 5px !important;
        border: 0px;
    }
    input.btn.btn-success.orange {
        background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
        border-color: #fea40d;
        border-radius: 15px;
        margin-right: 2%;
    }
    
    .black {
        background-color: #4c4c4d;
        border-radius: 15px;
    }
    
    .black:hover {
        background-color: #ffab04;
        border-color: #ffab04;
    }
    
    .field input,
    select {
        font-size: 1rem;
        border: 1px solid #ccc !important;
        font-family: inherit;
        cursor: text;
        color: #737272 !important;
        padding: 2% 3% !important;
        border-radius: 5px !important;
        width: 100% !important;
        height: 40px;
        font-size: 15px;
    }
    
    i.info {
        position: absolute;
        right: -6%;
        width: 15px;
        height: 15px;
        background: gray;
        text-align: center;
        color: #fff;
        border-radius: 50px;
        font-size: 10px;
        top: 35%;
    }
    
    p.info_d {
        transform: translate(120%, -50%) scale(1);
        display: none;
        position: absolute;
        width: 154px;
        right: 0;
        top: 49%;
        margin: 0;
        background: #333;
        color: #fff;
        font-size: 12px;
        text-align: center;
        padding: 3% 0%;
    }
    input.btn.btn-primary.black.cls_b {
        position: absolute;
        right: 5%;
        top: 91px;
        color: #000;
        font-weight: 600 !important;
        border: 0px;
        font-size: 22px;
        background: #eee;
        height: 36px;
        line-height: 0px;
        padding: 4px 15px 11px;
    }
    .pos i.fa.fa-chevron-down.ch {
    display: none;
}
    </style>
    
<template>
<CommonHeader />
<div id="main-content">
    <div id="page-container">
           <!--*************alert-->
        <div :class="alertClass" id="hidemessage" v-if="message"><p class="txt">{{alertMessage}}</p>
            <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
        </div>
    <!--*************alert-->
        <!--**************************common**************-->
        <h1 class="hed" style="">Earning </h1>
<button class="btn  url-btn" style="
    color: gray;margin-top: 1%;margin-left:0; color:black;float:right" v-on:click="myFunction">
<i class="fa fa-clone" aria-hidden="true"></i> Copy & Share Subscription Payment Link
</button>



<div class="clearfix"></div>
<div class="card_es">
        <div class="card_S">
            <h5 v-if="commission"><i class="fa fa-inr" aria-hidden="true"></i> {{total_commission}}</h5>
            <p>Total Commission</p>
        </div>
        <div class="card_S">
            <h5 v-if="commission">{{no_of_client}}</h5>
            <p>No. Of Client</p>
        </div>

        <div class="card_S">
            <h5 v-if="commission">{{no_of_expired_plan}}</h5>
            <p>Expired Plan</p>
        </div>
       </div>
        <div class="clear"></div>
        <div class="wrap1">
        <div class="calender">
            <input class="btn btn-success submit last_month" type="submit" v-on:click="last_month" value="Last Month">
            <input class="btn btn-success submit preview_month" type="submit"  v-on:click="current_month" value="Current Month">
            <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" class="cal1" style=""></datepicker>
            <span style="    width: 15px;
    text-align: center;
    margin-right: 2%;
    float: left;
    margin-top: 8px;">To</span>
            <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="end_date" class="cal1" style=""></datepicker>
            <button class="btn submit last_month" type="submit" v-on:click="custom_month" value="Custom Month" >Custom Month <img src="https://instabill.co/public/front/images/loader.gif"
                class="load_show"
                v-if="loader"
                style="width: 15px;"
              /></button>
            <div class="clearfix"></div>
        </div>
        <div class="search search_earning" style="">
            <input id="myInput" type="text" placeholder="Search.." v-model="search"  style=""> <button style="padding: 0;border: none;border-radius:10px;float: right;"><i class="fa fa-search" aria-hidden="true"></i></button>
        </div>
    </div>
        <table class="list-box">
            <tr class="list">
                <th>Insta Id</th>
                <th>Client Name</th>
                <th>Plan</th>
                <th>Date of payment</th>
                <th>Commission</th>
                <th>Payout status</th>
            </tr>
            <tr v-for="commissions in filteredCommission" :key="commissions">
                <td> {{commissions.userId}} </td>
                <td>{{commissions.user_name}}</td>
                <td><span class="amt">{{commissions.package}}</span><br>
                    <i class="fa fa-inr" aria-hidden="true"></i> {{commissions.package_amount}}
                </td>
                <td>{{commissions.date_of_payment}}</td>
                <td v-on:click="payment_detail(commissions.id)" class="cursor"><span><i class="fa fa-inr" aria-hidden="true"></i>{{commissions.ca_commission_amount}} <i class="fa fa-external-link" aria-hidden="true"></i></span></td>
           
                <td><button class="renew">{{commissions.status}}</button></td>
            </tr>

        </table>

        <!--******************commision_details_sidebar**************-->
         
        <div class="commision_details" v-if="commision_details">
        <div class="overlay" v-on:click="closecommision()"></div>   
            <p class="head_sb">Details <span aria-hidden="" class="closed_open_m" v-on:click="closecommision()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
            <br>
            <p><b>Client Paid</b> <span><i class="fa fa-inr" aria-hidden="true"></i>{{package_amount}} </span></p>
            <p><b>Date Of Payment</b> <span > {{date_of_payment}}</span></p>
            <p><b>Plan</b> <span class="amt">{{user_package}} </span></p>
            <p><b>Commission Amount</b> <span><i class="fa fa-inr" aria-hidden="true"></i>{{ca_commission_amount}}</span></p>
            <p><b>Commission Paid On</b> <span>{{commision_paid_date}}</span></p>
            <p><b>Transaction ID</b><span>{{transaction_id}}</span></p>
         </div>
        
    </div>
</div>
</template>

<script>
import CommonHeader from './CommonHeader.vue';
import {postApi} from '../assets/js/helper.js';
import datepicker from 'vue3-datepicker'
export default {
    name: 'UserEarning',
    components: {
          datepicker,
        CommonHeader
        
    },

    data() {
        return {
            date: new Date(new Date().getFullYear(),new Date().getMonth(),1),
            date2: new Date(new Date().getFullYear(),new Date().getMonth()+1,0),
            commision_details: false,
            url:`https://instabill.co/referral_link/${localStorage.getItem('admin_id')}`,
            commission:"",
            no_of_client:"0",
            total_commission:"0",
            no_of_expired_plan:"0",
            package_amount:"",
            date_of_payment:"",
            user_package:"",
            ca_commission_amount:"",
            commision_paid_date:"",
            transaction_id:"",
            search:"",
            loader:false,
               message: false,
            alertMessage:"",
            alertClass:"",
          
        }
    },
    created(){
this.commissionList(this.date,this.date2);

    },
    methods: {
        payment_detail(id) {
    var item = this.commission.find(item => item.id === id);
    this.package_amount=item.package_amount;       
    this.date_of_payment=item.date_of_payment;
    this.user_package=item.package;
    this.ca_commission_amount=item.ca_commission_amount ;
    this.commision_details = true;
    this.commision_paid_date=item.status;
    this.transaction_id=item.transaction_id;
        },
        closecommision() {
            this.commision_details = false;
        },
        myFunction(){

 const el = document.createElement('textarea');
  el.value = this.url;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

this.alertMessage="Referral link copied successfully";
            this.alertClass="success";
            this.message = true;
          
            setTimeout(() => this.message = false, 3000);


        },

        async commissionList(date,date2){
            this.loader = true;
           const response  = await postApi(
        '/ca_commissionList',
        
        {
          ca_id: localStorage.getItem('admin_id'),
          start_date:date,
          end_date:date2,
          
        },
        
        {},
        
      )
      
        try{
     if (response.errorCode == '0') {
         this.loader = false;
           this.commission=response.data;
           this.no_of_client=response.no_of_client;
           this.total_commission=response.total_commission;
           this.no_of_expired_plan=response.no_of_expired_plan;
          
            return false;
            
          } 
        
        }  
        catch(err){
                     this.alertMessage = err;
                this.alertClass = "error_msg";
                this.message = true;
                setTimeout(() => this.message = false, 1000);

    } 
        },
  custom_month(){
 this.commissionList(this.date,this.date2);

     },

 last_month(){
    this.date=new Date(new Date().getFullYear(),new Date().getMonth()- 1,1);
    this.date2=new Date(new Date().getFullYear(),new Date().getMonth(),0);
    
    this.commissionList(this.date,this.date2);
 },
 current_month(){
    this.date=new Date(new Date().getFullYear(),new Date().getMonth(),1);
    this.date2=new Date(new Date().getFullYear(),new Date().getMonth()+1,0);
    this.commissionList(this.date,this.date2);
 },
       close_msg() {
             this.message = false;
        }, 

    },
    computed: {
        filteredCommission: function () {
            var self = this;
            
            if(this.commission!="")
            {
                
            return this.commission.filter(function (person) {
                
                return person.user_name.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.userId.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.package.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.package_amount.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.date_of_payment.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.ca_commission_amount.toLowerCase().indexOf(self.search.toLowerCase()) >= 0
                || person.status.toLowerCase().indexOf(self.search.toLowerCase()) >= 0;
                
            });
            
        }
        else
        {
            return null;
        }
        }
    }

}
</script>

<style scoped>
.wrap1 input {
    padding: 5px !important;
}
button.btn.submit.last_month {
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    border-color: #fea40d;
    border-radius: 5px!important;
    margin-right: 1%;
    color: #fff;}
    .commision_details p span {
        float: right;
    }
    .card_es {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    .card_S {
    width: 32%;
    /* float: left; */
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 10px;
    padding: 3%;
    margin: 1% 0%;
    background: linear-gradient(85deg, #f66a59, #ffae00);
    height: 128px;
} 
    .calender {
        width: 79%;
        float: left;
        margin-left: 0%;
    }
    .clear{clear:both;}
    h1.hed {
        color: #f97d40;
         margin-left: 11px!important;
    }
    
    .amt {
        color: #f6f7fb;
        font-size: 12px;
        background: linear-gradient(85deg, #f66a59, #ffae00);
        padding: 2px 8px;
        border-radius: 5px;
        margin-top: 5px;
        display: inline-block;
    }
    .search.search_earning{
        width: 20% !important;
    float: right;
    margin: 0px;
}
.search.search_earning i {
    background: linear-gradient(85deg, #f66a59, #ffae00);
    padding: 10px !important;
    color: white;
    border-radius: 3px !important;
}
.search.search_earning input {
    border: 1px solid #d6d0d0 !important;
    padding: 2%;
    margin: 0 !important;
    border-radius: 5px !important;
    width: 80%;
    height: 37px;
}
    input#myInput {
        border: 2px solid black;
        background: transparent;
        /* border-bottom: 2px solid #ddd; */
    }
    
    .search i {
        background: linear-gradient(85deg, #f66a59, #ffae00);
        padding: 10px;
        color: white;
        border-radius: 10px;
    }
    input.cal1 {
    padding: 5%!important;
}
.renew{padding: 1px 10px;}
    table.list-box {
        margin-top: 2%;
    }
    .wrap1 {
        width: 99%;
    margin-top: 2%;
    margin-bottom: 1%;
    overflow: hidden;
}
    .search {
        width: 25%;
        float: right;
        margin-top: 2%;
    }
    
    .commision_details p b {
        color: #333;
    }
    .v3dp__datepicker input {
    padding: 6%!important;
    }
    h1.hed {
        color: #f97d40;
    
        float: left;
    }
    .msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #ffae00;transition: 1s;
}
p.txt {
    width: 91%;
    float: left;
    margin: 0;
}
.v3dp__datepicker {
    width:21%!important;}
.error_msg {
    position: fixed;
    top: 0%;
    z-index: 999;
    width: 20%;
    padding: 1%;
    background: #fff;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-radius: 5px;
    right: 0;
    border-left: 4px solid #db0014;
    transition: 1s;
    color: red;
}
    </style>
    
<template>
    <!DOCTYPE html>
    <html>
    <head>
        <title></title>
    </head>
    <body>
    <div class="thankyou">
        <div class="container">
            <div class="inner-thankyou">
                <h1>Thank you <span>!</span></h1>
                <p>Our team will contact you soon.</p>
                <p style="margin-top:-32px;padding-top:0px">Call us at +91 7982332316 </p>
    
                <a href="https://instabill.co/signup">Signup Instabill for FREE</a>
            </div>
        </div>
    </div>
    
    
    <div class="footer"><p style="margin:0px;">© Copyright All Right Reserved</p></div>
    </body>
    </html>
</template>

  
<script>
export default {
    name: "thankyou",
};
</script>

<style type="text/css">
*{margin: 0px;padding: 0px;}
.thankyou {
margin-top: 13%;
text-align: center;
}.thankyou h1 {
font-family: Lato, sans-serif;
color: #000;
letter-spacing: 1px;
font-size: 58px;
}.thankyou h1 span {
background-color: #f71735;
background-image: linear-gradient(147deg, #f8754b 0, #ffac01 74%);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
}.thankyou p {
font-family: Lato, sans-serif;
color: #000;
font-size: 21px;
letter-spacing: 0px;
margin-top: 0%;
margin-bottom: 3%;
font-weight: 600;
}.thankyou a {
background-color: #f71735;
background-image: linear-gradient(147deg, #f77150 0, #ffad02 74%);
border: 1px solid #fd9f13;
padding: 1%;
width: 22%;
text-align: center;
border-radius: 5px;
letter-spacing: 1px;font-family: Lato, sans-serif;
color: #fff;
margin-bottom: 10%;
text-decoration: none;
display: inline-block;
transition: 1s;
}.footer {
text-align: center;
padding: 2%;
background: #333;
color: #fff;font-family: Lato, sans-serif;
margin-top: 5%;
}
</style>






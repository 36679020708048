<template>
  <div :class="alertClass" id="hidemessage" v-if="message">
    <p class="txt">{{ alertMessage }}</p>
    <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
  </div>
  <!--*************alert red-->
  <!--*************alert green-->
  <div :class="alertClassg" id="hidemessageg" v-if="messageg">
    <p class="txt">{{ alertMessageg }}</p>
    <i class="fa fa-times close_msgg" aria-hidden="true" @click="close_msgg()"></i>
  </div>
  <!--*************alert green-->
  <div id="main-content">
    <div id="page-container">
      <!--**************************common**************-->
      <h1 class="hed">Client List</h1>
      <div class="clear"></div>
      <h1 class="head">
        <span class="tab selected" @click="bgcolor(3)" id="all">All</span>
        <span class="tab" @click="bgcolor(1)" id="expired">Expired</span>
        <span class="tab" @click="bgcolor(2)" id="active">Active</span>
      </h1>

      <div class="search search_client" style="">

        <span class="add_pro" role="button" @click="showModel">Add Profile</span>
        <input id="myInput" type="text" placeholder="Search.." @keyup="performSearch(this)" v-model="search" />
        <button style="padding: 0px;border: 0px;border-radius: 10px;margin-left: 5px;">
          <i class="fa fa-search" aria-hidden="true"></i>
        </button>
      </div>
      <div class="clearfix"></div>
      <table class="list-box">
        <tr class="list">
          <th>Profile Name</th>
          <th>GSTIN</th>
          <th>Current Plan</th>
          <th>Email</th>
          <th>Mobile No.</th>
          <th style="width:13%;text-align: center; padding-right: 3%;">
            Action
          </th>
        </tr>
        <tr v-if="clients == ''">
          <td colspan="5" align="center">No data Found</td>
        </tr>
        <tr v-else v-for="(client, index) in clients" :key="index">
          <td>{{ client.company_detail.company_name }}</td>
          <td>{{ client.company_detail.gstin_text }}</td>
          <td>
            <span class="amt">{{ client.package }}</span>
          </td>
          <td>{{ client.company_detail.email }}</td>
          <td>{{ client.company_detail.mobile }}</td>
          <td align="center" v-if="client.status == '0'">
            <a href="" class="badge badge-danger badge-pill"
              style="float: left;height: 26px;width: 50px;padding: 7px;margin: 1px"
              v-on:click="actionClientList(client.id, 2)">Reject</a>

            <a href="" class="badge badge-success badge-pill"
              style="float: left;height: 26px;width: 50px;padding: 7px;margin: 1px"
              v-on:click="actionClientList(client.id, 1)">Accept</a>
          </td>

          <td align="center" v-else>
            <router-link :to="{
              name: 'ViewDetails',
              params: {
                user_id: client.user_id,
                company_id: client.company_id,
              },
            }"><button class="view_d">View Detail</button></router-link>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <!-- sidebar -->
  <div v-if="addClient" class="add_profile">
    <h3>Add Profile</h3>
    <span role="button" aria-hidden="" @click="hideModel" class="closed_profile_m" data-v-ce39f946=""><i
        class="fa fa-times" aria-hidden="true" data-v-ce39f946=""></i></span>
    <form autocomplete="off" @submit.prevent="handleSubmit">
      <div class="profile_in">
        <!-- <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" v-model="gst_composite_scheme" role="switch" id="gstComposite">
          <label class="form-check-label" for="gstComposite">Gst Composite</label>
        </div>   -->
        <label>Gst Composite</label>
        <label class="switch">
          <input type="checkbox" v-model="gst_composite_scheme">
          <span class="sliderr round"></span>
        </label>
      </div>
      <div class="profile_in">
        <label>GSTIN * </label>
        <input type="text" @keyup="getGSTINDetails" v-model="gstin_text" required>
      </div>
      <div class="profile_in">
        <label>Company Name *</label>
        <input type="text" v-model="company_name" required>
      </div>
      <div class="profile_in">
        <label>Company Logo</label>
        <input type="file" @change="handleFileChange" accept="image/*">
      </div>
      <div class="profile_in">
        <label>Address * </label>
        <input type="text" v-model="address" required>
      </div>
      <div class="profile_in">
        <label>Pincode * </label>
        <input type="text" v-model="pincode" required>
      </div>
      <div class="profile_in">
        <label>Email * </label>
        <input type="text" v-model="email" required>
      </div>
      <div class="profile_in">
        <label>Mobile *</label>
        <input type="text" v-model="mobile" required>
      </div>
      <div class="profile_in">
        <label>Password *</label>
        <input type="text" v-model="password" required>
      </div>
      <div class="profile_in">
        <label>Confirm Password *</label>
        <input type="text" v-model="cpassword" required>
      </div>
      <!-- <div class="profile_in">
        <label>Nature of Business</label>
        <select name="business_type" class="business" required="">
          <option value="">Nature of Business</option>
          <option value="Manufacture">Manufacture</option>
          <option value="Wholeseller">Wholeseller</option>
          <option value="Trader / Retailer">Trader / Retailer</option>
          <option value="Pharma / Chemist" selected="">Pharma / Chemist</option>
          <option value="Travelling Agency">Travelling Agency</option>
          <option value="Service Provider">Service Provider</option>
          <option value="Others">Others</option>
        </select>
      </div>
      <div class="profile_in">
        <label>Type of Entity</label>
        <select name="entity_type" class="business" required="">
          <option value="">Type of Entity</option>
          <option value="Sole Proprietor">Sole Proprietor</option>
          <option value="Partnership Firm" selected="">Partnership Firm</option>
          <option value="LLP">LLP</option>
          <option value="OPC">OPC</option>
          <option value="Private Limited Company">Private Limited Company</option>
          <option value="Public Limited Company">Public Limited Company</option>
          <option value="Society/Trust/NGO">Society/Trust/NGO</option>
          <option value="Others">Others</option>
        </select>
      </div>
      <div class="profile_in">
        <label>CIN</label>
        <input type="text" id="cin">
      </div>
      <div class="profile_in">
        <label>PAN</label>
        <input type="text" id="pan">
      </div>
      <div class="profile_in">
        <label>Website</label>
        <input type="text" id="website">
      </div>
      <div class="profile_in">
        <label>License Title</label>
        <input type="text" id="licenseTitle">
      </div>
      <div class="profile_in">
        <label>License Number</label>
        <input type="text" id="licenseNumber">
      </div> -->
      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import $ from 'jquery';

export default {
  name: "viewClientList",
  components: {
  },
  props: ["clients"],
  data() {
    return {
      alertClass: "",
      message: false,
      alertClassg: "",
      messageg: false,
      search: "",
      company_name: "",
      email: "",
      password: "",
      cpassword: "",
      address: "",
      mobile: "",
      gstin_text: "",
      gst_composite_scheme: false,
      pincode: "",
      company_logo: null,
      addClient: false,
      gstinError: "",
      passwordError: "",
    };
  },
  methods: {
    actionClientList(id, status) {
      event.preventDefault();
      this.$emit("changeStatus", {
        id: id,
        status: status,
      });
    },
    bgcolor(id) {
      var div = document.querySelectorAll(".selected");
      for (var i = 0; i < div.length; i++) {
        div[i].classList.remove("selected");
      }

      if (id == "3") {
        document.getElementById("all").classList.add("selected");
        this.search = "";
      } else if (id == "2") {
        document.getElementById("active").classList.add("selected");
        this.search = "Active";
      } else if (id == "1") {
        document.getElementById("expired").classList.add("selected");
        this.search = "Expired";
      }
    },
    close_msg() {
      this.message = false;
    },
    close_msgg() {
      this.messageg = false;
    },
    performSearch() {
      var value = (this.search).toLowerCase();

      $("table tr").each(function (index) {
        if (index !== 0) {
          var id = $(this).children().text().toLowerCase()
          if (id.indexOf(value) < 0) {
            $(this).hide();
          } else {
            $(this).show();
          }
        }
      });
    },
    handleFileChange(event) {
      this.company_logo = event.target.files[0];
    },
    validateGSTIN() {
      // Check GSTIN length
      if (this.gstin_text.length !== 15) {
        this.gstinError = "Please enter a valid GSTIN no.";
        this.showAlert(this.gstinError);
      } else {
        this.gstinError = "";
      }
    },
    validatePassword() {
      // Check password length
      if (this.password.length < 8) {
        this.passwordError = "Password must be at least 8 characters.";
        this.showAlert(this.passwordError);
      }else if(this.password != this.cpassword){
        this.passwordError = "password and confirm password do not match";
        this.showAlert(this.passwordError);
      } else {
        this.passwordError = ""; // Clear error
      }
    },
    showAlert(message) {
      this.alertMessage = message;
      this.alertClass = "error_msg";
      this.message = true;
      setTimeout(() => this.message = false, 3000);
    },
    handleSubmit() {
      this.validateGSTIN();
      this.validatePassword();
      if (this.gstinError || this.passwordError) {
        return;
      }
      this.getGSTINDetails().then(() => {
        if (this.gstinError) {
          return;
        }
        let formData = new FormData();
        formData.append('ca_id', localStorage.getItem('admin_id'));
        formData.append('company_name', this.company_name);
        formData.append('email', this.email);
        formData.append('password', this.password);
        formData.append('address', this.address);
        formData.append('mobile', this.mobile);
        formData.append('gstin_text', this.gstin_text);
        formData.append('gst_composite_scheme', this.gst_composite_scheme ? '1' : '0');
        formData.append('pincode', this.pincode);
        if (this.company_logo) {
          formData.append('company_logo', this.company_logo);
        }
        axios.post("https://instabill.co/api/ca_client_register", formData, {
          headers: {
          },
        }).then((response) => {
          // console.log(response);  
          if (response.data.errorCode == "1") {
            this.alertMessage = response.data.errorMsg;
            this.alertClass = "error_msg";
            this.message = true;
            setTimeout(() => this.message = false, 3000);
          } else {
            this.addClient = false;
            this.alertMessageg = 'Profile Add Successfully';
            this.alertClassg = "error_msgg";
            this.messageg = true;
            setTimeout(() => this.messageg = false, 3000);
          }
        }).catch((error) => {
          console.log(error);
        });
      });
    },
    getGSTINDetails() {
      if (this.gstin_text.length == 15) {
        return new Promise((resolve, reject) => {
          axios.post(`https://instabill.co/api/store_gstin?gstin=${this.gstin_text}`, {}, {
            headers: {},
          }).then((response) => {
            // console.log(response);
            if (response.data.status === "success") {
              // console.log('Valid GSTIN');
              const data = response.data.data;
              this.company_name = data.lgnm;
              this.address = `${data.bno || ''} ${data.bnm || ''} ${data.st || ''} ${data.loc || ''} ${data.dst || ''} ${data.stj || ''}`.trim();
              if (data.email) {
                this.email = data.email;
              }
              if (data.mobile) {
                this.mobile = data.mobile;
              }
              this.pincode = data.pncd || '';
              resolve();
            } else {
              this.gstinError = "Invalid GSTIN Number";
              this.showAlert(this.gstinError);
              reject();
            }
          }).catch((error) => {
            reject(error);
          });
        });
      }
    },
    showModel() {
      this.addClient = true;
    },
    hideModel() {
      this.addClient = false;
    }
  },

};
</script>

<style scope>
.switch {
  position: relative !important;
  display: inline-block !important;
  width: 38px !important;
  height: 20px !important;
  margin: 0 0 0 5px;
}

.switch input {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.sliderr {
  position: absolute !important;
  cursor: pointer !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: #ccc !important;
  transition: 0.4s !important;
  border-radius: 20px !important;
}

.sliderr:before {
  position: absolute !important;
  content: "" !important;
  height: 14px !important;
  width: 14px !important;
  left: 2px !important;
  bottom: 3px !important;
  background-color: white !important;
  transition: 0.4s !important;
  border-radius: 50% !important;
}

input:checked+.sliderr {
  background: linear-gradient(85deg, #f66a59, #ffae00)!important;
}

input:checked+.sliderr:before {
  transform: translateX(20px) !important;
}

span.closed_profile_m {
  position: absolute;
  right: 5%;
  top: 16px;
  font-size: 21px;
  color: gray;
  font-weight: 100 !important;
}

.add_profile {
  float: right;
  z-index: 99;
  position: fixed;
  right: 0;
  top: 0;
  background: white;
  height: 100%;
  transition: 1s;
  border: 1px solid #d4d7dc;
  border-right: none;
  border-top: none;
  box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
  width: 350px;
  padding: 15px;
  overflow-y: scroll;
}

.add_profile h3 {
  color: #f97d40;
  font-size: 23px !important;
  text-transform: uppercase;
  margin-bottom: 10px;
  line-height: 35px;
  font-weight: 600 !important;
  letter-spacing: 0px;
}

.amt {
  color: #f6f7fb;
  font-size: 12px;
  background: linear-gradient(85deg, #f66a59, #ffae00);
  padding: 2px 8px;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
}

h1.hed {
  color: #f97d40;
}

.clear {
  clear: both;
}

span.add_pro {
  background: linear-gradient(85deg, #f66a59, #ffae00);
  color: #fff;
  padding: 9px 11px;
  margin-right: 5px;
  border-radius: 5px;
  font-weight: 500 !important;
}




input#myInput {
  border: 2px solid black;
  background: transparent;
  margin: 2px;
  /* border-bottom: 2px solid #ddd; */
}

.search.search_client {
  width: 33% !important;
  float: right;
}

h1.head {
  margin-left: 0px;
}

.search.search_client i {
  background: linear-gradient(85deg, #f66a59, #ffae00);
  padding: 10px !important;
  color: white;
  border-radius: 3px !important;
}

.search.search_client input {
  border: 1px solid #d6d0d0 !important;
  padding: 2%;
  border-radius: 5px !important;
}

.search.search_client i {
  background: linear-gradient(85deg, #f66a59, #ffae00);
  padding: 11px 10px !important;
  color: white;
  border-radius: 3px !important;
}

.search i {
  background: linear-gradient(85deg, #f66a59, #ffae00);
  padding: 10px;
  color: white;
  border-radius: 10px;
}

table.list-box {
  margin-top: 2%;
}

.search {
  margin-top: 16px;
  width: 25%;
  float: left;
}

.selected {
  background: linear-gradient(85deg, #f66a59, #ffae00) !important;
  color: #fff !important;
}

h1.hed {
  color: #f97d40;
  width: auto;
  float: left;
  color: #fb8f29;
  border-bottom: 1px solid #fb8f29;
  padding-bottom: 1%;
  display: inline;
}

h1.head {
  width: 60%;
  float: left;
}

span.closed_profile_m {
  position: absolute;
  right: 5%;
  top: 6%;
  font-size: 21px;
  color: gray;
  font-weight: 100 !important;
}

.profile_in input {
  border: 1px solid rgb(216, 224, 229);
  padding: 7px;
  border-radius: 5px !important;
  width: 100%;
}

.profile_in select {
  border: 1px solid rgb(216, 224, 229);
  padding: 7px;
  border-radius: 5px !important;
  width: 100%;
}

.profile_in label {
  font-size: 13px;
  font-weight: 400;
  /* margin-top: 12px; */
}

.add_profile button {
  background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
  border-color: #fea40d;
  width: auto;
  float: right;
  padding: 9px 14px;
  font-size: 14px;
  margin-top: 17px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
</style>

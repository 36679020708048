<template>
    <CommonHeader />
    
    <div id="main-content">
        <div id="page-container">
         <!--*************alert-->
            <div :class="alertClass" id="hidemessage" v-if="message"><p class="txt">{{alertMessage}}</p>
                <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
            </div>
        <!--*************alert-->
          
            <div class="dt_ban">
                <div class="dt_bana">
                    <h3>Upcoming Due Dates</h3>
                </div>
                <div class="date_s">
                
                    <form v-on:submit.prevent="get_e_startup_notification" method="post">
                    <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" class="cal1" style="padding: 4%;"></datepicker>
                    <span style="    width: 26px;
    text-align: center;
    margin-right: 1%;
    float: left;margin-top: 8px;">To</span>
                    <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="end_date" class="cal1" style="padding: 4%;"></datepicker>
                    <button class="dt_submit">Submit</button>
                    </form>

                </div>
                <div class="clear"></div>
                <div class="dt_list" v-if="notificationData">
                    <ul>
                        <li v-for="(notifi,index) in notificationData" :key="index">
                           <div class="dt_d">
                           
                            <p> {{ (notifi.due_date).substr(8,2)   }}   <br><span>
                                    
                                    {{ new Date(notifi.due_date).toLocaleString('default', { month: 'long' }).slice(0,3) }}</span> </p>
                            </div>
                            <div class="dt_info">
                                <b>{{notifi.title}}</b>
                                <p>{{notifi.description}}</p>
                            </div>
                        </li>
                       
                        <div class="clear"></div>
                    </ul>
                </div>
            </div>
            <div class="clear"></div>
           
    
            <!--*************alert-->
        
            <!--*******sidebar*****-->
            <div class="send_invitation" v-if="send_invitation">
                <p class="head_sb">Send Invitation to Client<span aria-hidden="" class="closed_open_m" v-on:click="send_invitationclose()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
            <form v-on:submit.prevent="send_invitation_func" method="post">
                <div class="my_pro1" >
                    <label>Registered Email Id*</label><br>
                    <input type="text" v-model="email" @change="showprofile" required>
                </div>
    
                <div class="my_pro1" v-if="s_profile" >
                    <label>Select Profiles</label><br>
                  
                  <a type="button"  class="profiles"  @click="changebg" 
                   v-for="(profile,index) in subProfile" :key="index" >
                          <input type="radio" :value="profile.id" v-model="select_profile">
                         <p>{{profile.company_name}}</p></a>
                </div>
    
                <div class="my_pro1">
                    <button type="submit" >Submit </button>
                </div>
                </form>
            </div>
            <!--*******download_excel_sidebar end*****-->
    
    
      <!--*******sidebar*****-->
            <div class="Share_instabill_sign_up" v-if="Share_instabill_sign_up">
                <p class="head_sb">Share Instabill Sign Up<span aria-hidden="" class="closed_open_m" v-on:click="cls_instabill_sign_up()"><i class="fa fa-times" aria-hidden="true"></i></span></p>
               <form v-on:submit.prevent="share_instabill_func" method="post">
               <div class="my_pro1">
                    <label>Name*</label><br>
                    <input type="text" v-model="name" required @change="writeNote">
                </div>
               <div class="my_pro1">
                    <label>WhatsApp Number*</label><br>
                    <div class="countrycode" style="border:1px solid #c7c7c7!important;border-radius: 5px;">
                        <input list="browsers" placeholder="+91" v-model="country_code" required  style="width: 18%;border: 0;">
                        <datalist id="browsers">
                            <option value="+91">
                            </option>
                            <option value="+93">
                            </option>
                            <option value="+355">
                            </option>
                            <option value="+213">
                            </option>
                            <option value="+1-684">
                            </option>
                            <option value="+376">
                            </option>
                            <option value="+244">
                            </option>
                            <option value="+1-264">
                            </option>
                            <option value="+672">
                            </option>
                            <option value="+1-268">
                            </option>
                            <option value="+54">
                            </option>
                            <option value="+374">
                            </option>
                            <option value="+297">
                            </option>
                            <option value="+61">
                            </option>
                            <option value="+43">
                            </option>
                            <option value="+994">
                            </option>
                            <option value="+1-242">
                            </option>
                            <option value="+973">
                            </option>
                            <option value="+880">
                            </option>
                            <option value="+1-246">
                            </option>
                            <option value="+375">
                            </option>
                            <option value="+32">
                            </option>
                            <option value="+501">
                            </option>
                            <option value="+229">
                            </option>
                            <option value="+1-441">
                            </option>
                            <option value="+975">
                            </option>
                            <option value="+591">
                            </option>
                            <option value="+387">
                            </option>
                            <option value="+267">
                            </option>
                            <option value="+55">
                            </option>
                            <option value="+246">
                            </option>
                            <option value="+1-284">
                            </option>
                            <option value="+673">
                            </option>
                            <option value="+359">
                            </option>
                            <option value="+226">
                            </option>
                            <option value="+257">
                            </option>
                            <option value="+855">
                            </option>
                            <option value="+237">
                            </option>
                            <option value="+1">
                            </option>
                            <option value="+238">
                            </option>
                            <option value="+1-345">
                            </option>
                            <option value="+236">
                            </option>
                            <option value="+235">
                            </option>
                            <option value="+56">
                            </option>
                            <option value="+86">
                            </option>
                            <option value="+61">
                            </option>
                            <option value="+57">
                            </option>
                            <option value="+269">
                            </option>
                            <option value="+682">
                            </option>
                            <option value="+506">
                            </option>
                            <option value="+385">
                            </option>
                            <option value="+53">
                            </option>
                            <option value="+599">
                            </option>
                            <option value="+357">
                            </option>
                            <option value="+420">
                            </option>
                            <option value="+243">
                            </option>
                            <option value="+45">
                            </option>
                            <option value="+253">
                            </option>
                            <option value="+1-767">
                            </option>
                            <option value="+670">
                            </option>
                            <option value="+593">
                            </option>
                            <option value="+20">
                            </option>
                            <option value="+503">
                            </option>
                            <option value="+240">
                            </option>
                            <option value="+291">
                            </option>
                            <option value="+372">
                            </option>
                            <option value="+251">
                            </option>
                            <option value="+500">
                            </option>
                            <option value="+298">
                            </option>
                            <option value="+679">
                            </option>
                            <option value="+358">
                            </option>
                            <option value="+33">
                            </option>
                            <option value="+689">
                            </option>
                            <option value="+241">
                            </option>
                            <option value="+220">
                            </option>
                            <option value="+995">
                            </option>
                            <option value="+49">
                            </option>
                            <option value="+233">
                            </option>
                            <option value="+350">
                            </option>
                            <option value="+30">
                            </option>
                            <option value="+299">
                            </option>
                            <option value="+1-473">
                            </option>
                            <option value="+1-671">
                            </option>
                            <option value="+502">
                            </option>
                            <option value="+44-1481">
                            </option>
                            <option value="+224">
                            </option>
                            <option value="+245">
                            </option>
                            <option value="+592">
                            </option>
                            <option value="+509">
                            </option>
                            <option value="+504">
                            </option>
                            <option value="+852">
                            </option>
                            <option value="+36">
                            </option>
                            <option value="+354">
                            </option>
                            <option value="+91">
                            </option>
                            <option value="+62">
                            </option>
                            <option value="+98">
                            </option>
                            <option value="+964">
                            </option>
                            <option value="+353">
                            </option>
                            <option value="+44-1624">
                            </option>
                            <option value="+972">
                            </option>
                            <option value="+39">
                            </option>
                            <option value="+225">
                            </option>
                            <option value="+1-876">
                            </option>
                            <option value="+81">
                            </option>
                            <option value="+44-1534">
                            </option>
                            <option value="+962">
                            </option>
                            <option value="+7">
                            </option>
                            <option value="+254">
                            </option>
                            <option value="+686">
                            </option>
                            <option value="+383">
                            </option>
                            <option value="+965">
                            </option>
                            <option value="+996">
                            </option>
                            <option value="+856">
                            </option>
                            <option value="+371">
                            </option>
                            <option value="+961">
                            </option>
                            <option value="+266">
                            </option>
                            <option value="+231">
                            </option>
                            <option value="+218">
                            </option>
                            <option value="+423">
                            </option>
                            <option value="+370">
                            </option>
                            <option value="+352">
                            </option>
                            <option value="+853">
                            </option>
                            <option value="+389">
                            </option>
                            <option value="+261">
                            </option>
                            <option value="+265">
                            </option>
                            <option value="+60">
                            </option>
                            <option value="+960">
                            </option>
                            <option value="+223">
                            </option>
                            <option value="+356">
                            </option>
                            <option value="+692">
                            </option>
                            <option value="+222">
                            </option>
                            <option value="+230">
                            </option>
                            <option value="+262">
                            </option>
                            <option value="+52">
                            </option>
                            <option value="+691">
                            </option>
                            <option value="+373">
                            </option>
                            <option value="+377">
                            </option>
                            <option value="+976">
                            </option>
                            <option value="+382">
                            </option>
                            <option value="+1-664">
                            </option>
                            <option value="+212">
                            </option>
                            <option value="+258">
                            </option>
                            <option value="+95">
                            </option>
                            <option value="+264">
                            </option>
                            <option value="+674">
                            </option>
                            <option value="+977">
                            </option>
                            <option value="+31">
                            </option>
                            <option value="+599">
                            </option>
                            <option value="+687">
                            </option>
                            <option value="+64">
                            </option>
                            <option value="+505">
                            </option>
                            <option value="+227">
                            </option>
                            <option value="+234">
                            </option>
                            <option value="+683">
                            </option>
                            <option value="+850">
                            </option>
                            <option value="+1-670">
                            </option>
                            <option value="+47">
                            </option>
                            <option value="+968">
                            </option>
                            <option value="+92">
                            </option>
                            <option value="+680">
                            </option>
                            <option value="+970">
                            </option>
                            <option value="+507">
                            </option>
                            <option value="+675">
                            </option>
                            <option value="+595">
                            </option>
                            <option value="+51">
                            </option>
                            <option value="+63">
                            </option>
                            <option value="+64">
                            </option>
                            <option value="+48">
                            </option>
                            <option value="+351">
                            </option>
                            <option value="+974">
                            </option>
                            <option value="+242">
                            </option>
                            <option value="+262">
                            </option>
                            <option value="+40">
                            </option>
                            <option value="+7">
                            </option>
                            <option value="+250">
                            </option>
                            <option value="+590">
                            </option>
                            <option value="+290">
                            </option>
                            <option value="+1-869">
                            </option>
                            <option value="+1-758">
                            </option>
                            <option value="+508">
                            </option>
                            <option value="+1-784">
                            </option>
                            <option value="+685">
                            </option>
                            <option value="+378">
                            </option>
                            <option value="+239">
                            </option>
                            <option value="+966">
                            </option>
                            <option value="+221">
                            </option>
                            <option value="+381">
                            </option>
                            <option value="+248">
                            </option>
                            <option value="+232">
                            </option>
                            <option value="+65">
                            </option>
                            <option value="+1-721">
                            </option>
                            <option value="+421">
                            </option>
                            <option value="+386">
                            </option>
                            <option value="+677">
                            </option>
                            <option value="+252">
                            </option>
                            <option value="+27">
                            </option>
                            <option value="+82">
                            </option>
                            <option value="+211">
                            </option>
                            <option value="+34">
                            </option>
                            <option value="+94">
                            </option>
                            <option value="+249">
                            </option>
                            <option value="+597">
                            </option>
                            <option value="+47">
                            </option>
                            <option value="+268">
                            </option>
                            <option value="+46">
                            </option>
                            <option value="+41">
                            </option>
                            <option value="+963">
                            </option>
                            <option value="+886">
                            </option>
                            <option value="+992">
                            </option>
                            <option value="+255">
                            </option>
                            <option value="+66">
                            </option>
                            <option value="+228">
                            </option>
                            <option value="+690">
                            </option>
                            <option value="+676">
                            </option>
                            <option value="+1-868">
                            </option>
                            <option value="+216">
                            </option>
                            <option value="+90">
                            </option>
                            <option value="+993">
                            </option>
                            <option value="+1-649">
                            </option>
                            <option value="+688">
                            </option>
                            <option value="+1-340">
                            </option>
                            <option value="+256">
                            </option>
                            <option value="+380">
                            </option>
                            <option value="+971">
                            </option>
                            <option value="+44">
                            </option>
                            <option value="+1">
                            </option>
                            <option value="+598">
                            </option>
                            <option value="+998">
                            </option>
                            <option value="+678">
                            </option>
                            <option value="+379">
                            </option>
                            <option value="+58">
                            </option>
                            <option value="+84">
                            </option>
                            <option value="+681">
                            </option>
                            <option value="+212">
                            </option>
                            <option value="+967">
                            </option>
                            <option value="+260">
                            </option>
                            <option value="+263">
    
                            </option>
                        </datalist>
                        <input style="border: 0;padding: 0; border-radius: 5px;width: 183px;" type="tel" v-model="mobile" placeholder="Enter WhatsApp Number" required ><br>
    
                    </div>
                </div>
                  
                <div class="my_pro1">
                    <label>Email*</label><br>
                    <input type="text" v-model="email" required>
                </div>
                <div class="my_pro1">
                    <label>Message*</label><br>
                    <textarea rows="5" v-model="note" readonly></textarea>
                </div>
                <div class="my_pro1">
                    <button type="submit">Submit <img src="https://instabill.co/public/front/images/loader.gif"
                    class="load_show"
                    v-if="loader"
                    style="width: 15px;"
                  /></button>
                </div>
                </form>
            </div>
            <!--*******sidebar end*****-->
        </div>
    </div>
    </template>
    
    <script>
    import CommonHeader from './CommonHeader.vue';
    import {checkEmail, postApi} from '../assets/js/helper.js';
    import datepicker from 'vue3-datepicker'
    export default {
        name: 'DueDate',
        components: {
            datepicker,
            CommonHeader
        },
        data() {
            return {
                date: new Date(new Date().getFullYear(),new Date().getMonth(),1),
                date2: new Date(new Date().getFullYear(),new Date().getMonth()+1,0),
                Share_instabill_sign_up: false,
                send_invitation: false,
                
               s_profile:false,
               showMobileMenu: false,
    
               subProfile:null,
               email:"",
    
               select_profile:"",
    
               name:"",
               mobile:"",
               country_code:"+91",
               note:'',
    
               no_of_client:false,
               total_commission:false,
               pending_gst_return:false,
               check_no_of_client:false,
               check_pending_gst_return:false,
               loader:false,
                message: false,
                alertMessage:"",
                alertClass:"",
                notificationData:"",
                option1: false,
            }
        },
    created()
    {
    this.getDashboardData();
    this.get_e_startup_notification();
    },
    methods: {
        first1(){
  this.option1 = true;
},
close1(){
  this.option1 = false;
},
        
            opn_instabill_sign_up() {
                this.Share_instabill_sign_up = true;
                this.email="";
            },
            cls_instabill_sign_up() {
                this.Share_instabill_sign_up = false;
                 this.email="";
            },
            send_invitationshow() {
                this.send_invitation = true;
                 this.email="";
            },
            send_invitationclose() {
                this.send_invitation = false;
                 this.email="";
            },
    
            changebg(e){
                var div = document.querySelectorAll(".active");
                for (var i = 0; i < div.length; i++) {
                      div[i].classList.remove('active');
                }
                 e.target.parentNode.classList.add("active");
            } ,
          
            async getDashboardData(){
               const response  = await postApi(
            '/getDashboardData',
            {
              ca_id: localStorage.getItem('admin_id'),
            },
            {},
          )
          
            try{
         if (response.errorCode == '0') {
              
            console.log(response);
            this.check_no_of_client=true;
               this.check_pending_gst_return=true;
            this.no_of_client=response.no_of_client;
               this.total_commission=response.total_commission;
               this.pending_gst_return=response.pending_gst_return;
               
                return false;
              } 
            
            }  
            catch(err){
                 this.alertMessage = err;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
    
        } 
            },
    
           async showprofile(){
                var email=this.email;
                if (!checkEmail(email)) {
    
                     this.alertMessage = "Please enter valid email";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 1000);
    
         
                }
                
               if(email!="")
                {
                      const response = await postApi(
            "/get_user_subprofiles",
            {
            email: email,
            },
            {}
          );
          try {
            if (response.errorCode == "0") {
                console.log(response.data);
                this.subProfile=response.data;
                this.s_profile = true;
    
    return false;
            } 
            else
            {
                this.alertMessage = "Client email id not found";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
               
            }
          } catch (err) {
            alert(err);
          }
    
                
                }
            },
    
            async send_invitation_func(){
                var email=this.email;
                if(!checkEmail(email)) {
                     this.alertMessage = "Please enter valid email";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
         
                }
           
           var radio_data=this.select_profile;
     
           var admin_id=localStorage.getItem("admin_id");
           if(radio_data!="" && email!="")
           {
                         const response = await postApi(
            "/ca_send_invitation",
            {
            email: email,
            ca_id:admin_id,
            radio_data:radio_data,
            },
            {}
          );
          try {
            if (response.errorCode == "0") {
    
                this.subProfile="";
                this.email="";
                this.select_profile="";
                 this.alertMessage = "Invitation Sent Successfully";
                    this.alertClass = "success";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
    
                this.s_profile = false;
                this.send_invitation = false;
                    return false;
            } 
            else
            {
    
                   this.alertMessage = "Client email id not found";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
    
            
            }
          } catch (err) {
            alert(err);
          }   
           }
    
            },
    
    
    writeNote(){
        var name=this.name;
        var admin_name=localStorage.getItem("admin_name");
        this.note=`Hi ${name}
    
${admin_name} has sent you a request to signup Instabill.
    
Click on the link https://instabill.co/signup and set up your profile in 2 minutes
    
With Instabill, you can do:-
GST Invoicing
Customer Payment Collection
E-Invoicing
E-Way Bill
Banking
Inventory Management
PO, Quotation and many more….
    
Download Instabill Mobile App http://bit.ly/instabill-app
    
Happy Billing!
Team Instabill`;
    } ,
    async share_instabill_func(){
        var admin_name=localStorage.getItem("admin_name");
        var admin_id=localStorage.getItem("admin_id");
    
    if(!checkEmail(this.email)) {
    
             this.alertMessage = "Please enter valid email";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
        
            return false;
                }
            this.loader = true;
         const response = await postApi(
            "/ca_share_instabill",
            {
            name:this.name,
            email: this.email,
            mobile:this.mobile,
            country_code:this.country_code,
            ca_id:admin_id,
            admin_name:admin_name,
            },
            
            {}
            
          );
          
          try {
            if (response.errorCode == "0") {
                this.alertMessage="Instabill Share Successfully";
                this.alertClass="success";
                this.message = true;
              
                setTimeout(() => this.message = false, 3000);
    
                this.Share_instabill_sign_up = false;
                this.note="";
                this.name="";
                this.mobile="";
                 this.loader = false;
    return false;
            } 
            else
            {
             alert("Something Went Wrong");   
            }
          } catch (err) {
            alert(err);
          }   
        
    },
     close_msg() {
                 this.message = false;
            },

        async get_e_startup_notification(){
       
            const response = await postApi(
            "/get_e_startupindia_notification",
            {
                from:this.date,
                to: this.date2,
            },
            {}
            
          );

         try {
            if (response.data.status) {
             this.notificationData=response.data.detais;
                  return false;
            } 
            else
            {
             
             this.alertMessage="Notification not found";
                this.alertClass="success";
                this.message = true;
                this.notificationData="";

            }
          } catch (err) {
            alert("Data Not Found");   
          }   
        
    },
        },
        
    
    }
    </script>
    
    <style scoped>
    div#video-popup-container {
    position: fixed;
    z-index: 996;
    width: 60%;
    left: 50%;
    margin-left: -30%;
    top: 20%;
    background-color: #fff;
    text-align: center;
}

div#video-popup-close {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 998;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    text-align: center;
    font-size: 12px;
    background: #ffae00;
    line-height: 25px;
    color: #fff;
    z-index: 9999999999;
}

div#video-popup-iframe-container {
    position: absolute;
    z-index: 997;
    width: 100%;
    border-radius: 2px;
    background-color: #000000e0;
    padding: 5%;
    border-radius: 10px;
}
    /* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .dt_list {
    height: 225px;   margin-top: 2%;
}
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
    .dt_list li {
          width: 48%;
    float: left;
    list-style: none;
    margin-bottom: 2%;
    box-shadow: 12px 12px 24px 0 rgb(0 0 0 / 7%), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    /* overflow: hidden; */
    padding: 16px 14px;
    background: #fdfdfd;
    margin: 1%;
    max-height: 400px;
    height: 200px;
    }
    .dt_list {
        box-shadow: 12px 12px 24px 0 rgb(0 0 0 / 7%), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 2%;
    background: #ebeef9;
    display: inline-table;
}
button.dt_submit {
    width: auto;
    font-size: 15px;
    font-weight: 400;
    text-transform: capitalize;
    padding: 8px 17px !important;
}
    .dt_list ul {
        padding: 0;
    }
    .dt_info b {
        color: #000;
        font-size: 19px;
    }
    .dt_info {
        padding-left: 3%;
        width: 80%;
        float: left;
    }

    .dt_d {
        width: 15%;
        float: left;
        border-right: 1px solid #e2e2e2;
    }
    .dt_d p {
        background-color: #f71735;
        background-image: linear-gradient(147deg,#f7724f 0,#fea807 74%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 37px;
        line-height: 34px;
        margin-bottom: 0;
    }
    .dt_d p span {
        font-size: 26px;
    }
    .date_s .v3dp__datepicker {
        width: 23%;
        font-size: 15px;
    }
    .v3dp__datepicker {
    display: inline;
    float: left;
    width: 36% !important;}
    button.dt_submit {
          background: linear-gradient(85deg, #f66a59, #ffae00);
    border: 0px;
    color: #fff;
    padding: 6px 2%;
    border-radius: 5px;
    margin-top: 0%;
    }
    .dt_bana {
        width: 50%;
        float: left;
    }
    .dt_bana h3 {
        text-transform: uppercase;
        font-size: 18px;
        color: #000;
        font-weight: 600;
        margin-bottom: 2%;
    }
    .date_s {
        width: 50%;
        float: left;
        text-align: right;
    }
    .clear{clear:both}
        .dt_ban {
        background-size: 100% 100%;
        padding: 2% 2%;
        width: 94%;
        margin-left: 1%;
        border-radius: 5px;
        /* box-shadow: 0 0.5rem 1rem rgb(132 132 132 / 15%); */
        margin-top: 2%;
        position: relative;
    }
    .my_pro1 button {
        float: left;}
    .profiles {
             color: #000;
        border-radius: 50px;
        height: auto;
        border: 1px solid #ccc;
        align-items: center;
        justify-content: center;
        transition: all ease 1s;
        background: #fff;
        position: relative;
        padding: 5px 12px 0px;
        margin-right: 2%!important;
        font-size: 12px;
        margin-bottom: 2%;
    }
    .my_pro1 label {
        letter-spacing: 0.05em;
        font-size: 14px;
        color: #737272;
        margin-bottom: 2%;
    }
    img.add_acc {
        position: absolute;
        bottom: 0%;
        height: 212px;
        width: 23% !important;
        right: 3%;
    }
    .my_pro1 input{    background: #fff;}
    .profiles input {
           opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin: 0;
        cursor: pointer;
        height: 100%;
    }
    .profiles p {
            text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        display: inline-block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
        margin: 0;
        padding: 0;
    }
    .close_msg {
        position: absolute;
        right: 6%;
           top: 25%;
        font-size: 14px;cursor:pointer;
    }
    .learn_v {
    box-shadow: 12px 12px 24px 0 rgb(0 0 0 / 7%), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 2%;
    width: 63%;
    margin-bottom: 5%;
    overflow: hidden;
    float: left;
}

.right_br {
    float: left;
    width: 30%;
    margin-left: 2%;
}

.learn_v h2 {
    font-size: 26px;
    color: #000;
    letter-spacing: 0px;
    font-weight: 600;
    margin-bottom: 3%;
    margin-left: 0%;
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 7%;
}
.live_video a {
    font-weight: 400 !important;
    font-size: 14px;
}
.left_l h2 i {
    color: #ffae00;
}

.vpop img {
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
}

.learn_v h2 {
    font-size: 26px;
    color: #000;
    letter-spacing: 0px;
    font-weight: 600;
    margin-bottom: 3%;
    margin-left: 0%;
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-top: 7%;
}

.btn_Aa h2 i {
    color: #ffae00;
}

.one_A h3 {
    font-size: 19px;
    font-weight: 600;
    color: #ffae00;
    letter-spacing: 1px;
}

.one_A ul {
    padding: 0px;
}

.one_A ul li {
    list-style: none;
}

.one_A ul li a {
      font-size: 14px;
    display: flex;
    text-decoration: none;
    color: #333;
    font-weight: 400 !important;
}

.one_A ul li a i {
    margin-right: 8px;
    line-height: 28px;
}

.live_video {
    box-shadow: 12px 12px 24px 0 rgb(0 0 0 / 7%), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
    margin: 1%;
    padding: 1% 6%;
    margin-top: 0%;
    border-radius: 10px;
    background: #fff;
}

.cube_A {
    /* width: 28%; */
    background: #fff;
    box-shadow: 12px 12px 24px 0 rgb(0 0 0 / 7%), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
    margin: 1%;
    padding: 5%;
    margin-top: 6%;
    border-radius: 10px;
}

.live_video h2 {
    font-size: 40px;
    color: #000;
    letter-spacing: 0px;
    font-weight: 600;
    margin-bottom: 2%;
    margin-left: 1%;
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-bottom: 7%;
    position: relative;
}

span.large_f {
    font-size: 42px;
    line-height: 46px;
    color: #ffae00;
    font-weight: 500!important;
}

span.blink {
    position: absolute;
    right: 4% !important;
    top: -14% !important;
}

span.blink span {
    font-size: 48px;
    color: #ffae00;
    animation: blink 1s linear infinite;
}

b.dots_a {
    width: 20px;
    height: 20px;
    display: inline-block;
    background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%);
    border-radius: 50px;
    /* position: absolute; */
    margin-left: 10px;
    top: 32%;
}

.live_video p {
    font-size: 14px;
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin-bottom: 9px;
}

.cube_A p {
    white-space: normal !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 25px;
    font-weight: 100;
    color: #000;
    font-weight: 400!important;
}

.cube_A a {
    font-size: 13px;
    font-weight: 500!important;
}
    .video_s {
    margin-top: 4%;
}
    
    .sec {
        width: 24%;
        float: left;
    }
    
    .clear {
        clear: both;
    }
    
    button.paid1 {
        background: linear-gradient(85deg, #f66a59, #ffae00);
        border: 0px;
        color: #fff;
        padding: 3px 2%;
        border-radius: 5px;
        margin-top: 1%;
    }
    
    .sec i {
        color: #ffae00;
        font-size: 22px;
    }
    
    .banner1 {
        background-size: 100% 100%;
        padding: 3% 2%;
        width: 94%;
        margin-left: 1%;
        border-radius: 5px;
        box-shadow: 0 0.5rem 1rem rgb(132 132 132 / 15%);
        margin-top: 2%;position: relative;margin-top: 6%;
    }
    
    .banner1 h3 {
        font-size: 18px;
        color: #000;
        font-weight: 600;
        margin-bottom: 2%;
    }
    
    button.paid1 {
        margin-top: 2%;
    }
    
    .banner2 button.paid1 {
        margin-left: 7%;
    }
    
    .banner1 p {
        font-size: 13px;
        color: #000;
        margin-bottom: 1%;
        line-height: 23px;
        margin-top: 6%;
    }
    
    .banner2 {
        padding: 3% 2%;
        width: 94%;
        margin-left: 1%;
        border-radius: 5px;
        box-shadow: 0 0.5rem 1rem rgb(132 132 132 / 15%);
        margin-top: 2%;
        background: #fff;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
.clear{clear:both}
    .banner2 p {
        font-size: 13px;
        color: #000;
        margin-bottom: 1%;
        line-height: 23px;
        width: 50%;
        margin-top: 2%;
        margin-left: 7%;
    }
    .selected {
        border: 1px solid #ffae00!important;
    }
    .banner2 h3 {
        color: #000;
        font-size: 16px;
        font-weight: 600;
        margin: 0%;
        margin-left: 7%;
    }
    
    .send_invitation {
        /* display: none; */
        float: right;
        z-index: 9999;
        position: fixed;
        right: 0;
        top: 0px;
        background: #f0f0f0;
        height: 100%;
        transition: 1s;
        border: 1px solid #d4d7dc;
        border-right: none;
        border-top: none;
        box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
        width: 410px;
        padding: 15px;
    }
    .active{
    border:1px solid orange;    
    }
    
    textarea:focus,
    input:focus {
        outline: none;
    }
        .success {
        position: fixed;
        top: 0%;
       z-index: 999999999;
        width: 20%;
        padding: 1%;
        background: #fff;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 5px;
        right: 0;
        border-left: 4px solid #ffae00;transition: 1s;
    }
    p.txt {
        width: 91%;
        float: left;
        margin: 0;
    }
    .error_msg {
           position: fixed;
        top: 0%;
        z-index: 999999999;
        width: 20%;
        padding: 1%;
        background: #fff;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 5px;
        right: 0;
        border-left: 4px solid #db0014;
        transition: 1s;
        color: red;
    }
    </style>
    
import HomePage from './components/HomePage.vue'
import ViewDetails from './components/ViewDetails.vue'
import AllReports from './components/AllReports.vue'
import UserProfile from './components/UserProfile.vue'
import MyProfile from './components/MyProfile.vue'
import ClientList from './components/ClientList.vue'
import TaxPayment from './components/TaxPayment.vue'
import UserEarning from './components/UserEarning.vue'
import GstReturn from './components/GstReturn.vue'
import signIn from './components/signIn.vue'
import home from './components/home.vue'
import CreateAccount from './components/CreateAccount.vue'
import ImportBanking from './components/ImportBanking.vue'
import TallyIntegration from './components/TallyIntegration.vue'
import ChangePassword from './components/ChangePassword.vue'
import forgetPassword from './components/forgetPassword.vue'
import ViewBankstatement from './components/ViewBankstatement.vue'
import ViewBankstatements from './components/ViewBankstatements.vue'
import Gstr2b from './components/Gstr2b.vue'
import Documents from './components/Documents.vue'
import CompanyDetails from './components/CompanyDetails.vue'
import ViewList from './components/ViewList.vue'
import DueDate from './components/DueDate.vue'
import bookdemo from './components/bookdemo.vue'
import thankyou from './components/thankyou.vue'
import { createRouter, createWebHistory } from 'vue-router'
import ImportSales from './components/ImportSales.vue'
import ImportSalesReturn from './components/ImportSalesReturn.vue'
import ImportPurchases from './components/ImportPurchases.vue'
import ImportPurchasesReturn from './components/ImportPurchasesReturn.vue'
import ImportExpenses from './components/ImportExpenses.vue'
import Gstr2a from './components/Gstr2a.vue'

const routes = [
    {
        name: 'home',
        component: home,
        path: '/'
    },
    {
        name: 'thankyou',
        component: thankyou,
        path: '/thankyou'
    },
    {
        name: 'Gstr2a',
        component: Gstr2a,
        path: '/Gstr2a/:user_id/:company_id'
    },
    {
        name: 'bookdemo',
        component: bookdemo,
        path: '/bookdemo'
    },
    {
        name: 'signIn',
        component: signIn,
        path: '/signIn'
    },
    {
        name: 'CreateAccount',
        component: CreateAccount,
        path: '/signup'
    },

    {
        name: 'forgetPassword',
        component: forgetPassword,
        path: '/forgetPassword'
    },
    {
        name: 'HomePage',
        component: HomePage,
        path: '/Dashboard'
    },
     {
        name: 'ViewDetails',
        component: ViewDetails,
        path: "/ViewDetails/:user_id/:company_id",
        props: true
    },
     {
        name: 'AllReports',
        component: AllReports,
        path: '/AllReports'
    },
    {
        name: 'UserProfile',
        component: UserProfile,
        path: '/UserProfile/:user_id/:company_id',
        props: true
    },
    {
        name: 'MyProfile',
        component: MyProfile,
        path: '/MyProfile'
    },
    {
        name: 'ChangePassword',
        component:ChangePassword,
        path: '/ChangePassword'
    },
    {
        name: 'ClientList',
        component: ClientList,
        path: '/ClientList'
    },
    {
        name: 'TaxPayment',
        component: TaxPayment,
        path: '/TaxPayment'
    },
    {
        name: 'UserEarning',
        component: UserEarning,
        path: '/UserEarning'
    },
    {
        name: 'GstReturn',
        component: GstReturn,
        path: '/GstReturn'
    },
    {
        name: 'TallyIntegration',
        component: TallyIntegration,
        path: '/TallyIntegration/:user_id/:company_id',
        props: true
    },
    {
        name: 'ImportBanking',
        component: ImportBanking,
        path: '/ImportBanking/:user_id/:company_id/:csv_db_id/:bank_id',
        props: true
    },
    {
        name: 'ViewBankstatement',
        component:ViewBankstatement,
        path: '/ViewBankstatement/:user_id/:company_id',
        props:true
    },
    {
        name: 'ViewBankstatements',
        component: ViewBankstatements,
        path: "/ViewBankstatements/:user_id/:company_id/:bank_id",
        props: true
    },
    {
        name: 'Gstr2b',
        component:Gstr2b,
        path: "/Gstr2b/:user_id/:company_id/",
        props: true
    },
    {
        name: 'Documents',
        component: Documents,
        path: "/Documents/:user_id/:company_id/",
        props: true
    },
    {
        name: 'CompanyDetails',
        component: CompanyDetails,
        path: '/CompanyDetails'
    },
    {
        name: 'ViewList',
        component: ViewList,
        path: '/ViewList'
    },
    {
        name: 'DueDate',
        component: DueDate,
        path: '/DueDate'
    },
    {
        name: 'ImportSales',
        component: ImportSales,
        path: '/ImportSales/:user_id/:company_id/:csv_db_id',
        props: true
    },
    {
        name: 'ImportSalesReturn',
        component: ImportSalesReturn,
        path: '/ImportSalesReturn/:user_id/:company_id/:csv_db_id',
        props: true
    },
    {
        name: 'ImportPurchases',
        component: ImportPurchases,
        path: '/ImportPurchases/:user_id/:company_id/:csv_db_id',
        props: true
    },
    {
        name: 'ImportPurchasesReturn',
        component: ImportPurchasesReturn,
        path: '/ImportPurchasesReturn/:user_id/:company_id/:csv_db_id',
        props: true
    },
    {
        name: 'ImportExpenses',
        component: ImportExpenses,
        path: '/ImportExpenses/:user_id/:company_id/:csv_db_id',
        props: true
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
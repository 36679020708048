<template>
    <CommonHeader />
    <div id="main-content">
        <div id="page-container">
            <!--*************alert-->
            <div :class="alertClass" id="hidemessage" v-if="message">
                <p class="txt">{{alertMessage}}</p>
                <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
            </div>
            <!--*************alert-->
            <!--**************************common**************-->
            <h3 class="tally">Tally Integration</h3>
            <router-link :to="{ name: 'ViewDetails', params:{user_id:user_id , company_id:company_id} }"><button class="back_user_1 btn" style="    padding: 0;"><i class="fa fa-angle-double-left" aria-hidden="true"></i> Back</button></router-link>
            <div class="top_h">
                <div class="left_a">
                    <div class="boxes">
                        <h3 style="font-weight: 600;font-size: 16px;color:#000">Username: <span>{{username}}</span></h3>
                        <!-- <h3 style="font-weight: 600;font-size: 16px;color:#000">New Tally Username: <span style="    position: relative;"> <input ytpe="text" id="tally_username" v-model="tally_username" readonly v-on:change="tally_username_save">  <i class="fa fa-edit" aria-hidden="true" v-on:click="performEdit" style="    position: absolute;
    right: 7px;
    top: 3px;
    color: #333;"></i> </span></h3> -->
                        <h3 style="font-weight: 600;font-size: 16px;color:#000">Password: <span>{{tally_integration_password}}</span></h3>
    
                    </div>
    
                </div>
                <div class="date_sect">
                    <label>Data Sync From The Date</label>
                    <div class="date_fil">
                    <datepicker v-model="date" inputFormat="dd-MM-yyyy" id="start_date" placeholder="Select Date" class="cal1" > </datepicker>
               <button  @click="updateDate" class="btn_date">Submit</button>
            </div>
                </div>
                
                <div class="connected" v-if="connect"> Connected <div class="zoom-in-zoom-out"></div>
                </div>
    
                <div class="disconnect" v-if="disconnect">Disconnected <div class="zoom-in-zoom-out " style="background:red"></div>
                </div>
    
                <div class="clear"></div>
            </div>
    
            <div class="card_box">
                <p>Tally Add On</p>
                <a href="https://instabill.co/public/ca_images/Instabill_Tally_TCP.tcp"> <i class="fa fa-download" aria-hidden="true"></i> </a>
            </div>

            <a href="https://youtu.be/a6qhZ-8kjmc" target="_blank">   <div class="card_box">
                <p>Watch Demo</p>
                 <i class="fa fa-play-circle-o" aria-hidden="true"></i>
            </div>
        </a>

      <div class="card_box"  v-on:click="showModal()">
                <p>Tally Status Update</p>
                 <i class="fa fa-edit" aria-hidden="true"></i>
            </div> 
            
            <div class="sidebar_a" v-if="isModalVisible">
                <h2>Tally Status Update<button type="button" v-on:click="closeModal()" class="close" id="setting_close1" data-dismiss="alert" data-v-ce39f946=""><i class="fa fa-times" aria-hidden="true" data-v-ce39f946=""></i><span class="sr-only" data-v-ce39f946="">Close</span></button>
                </h2>
                
                <form v-on:submit.prevent="tally_status_update" method="post">
                    <div class="tally_status">
                        <label>User Id</label>
                        <input type="number" readonly v-model="userId"  class="form-control" placeholder="Enter User Id" required>
                    </div>
                    
                    <div class="tally_status">
                        <label>Company Id</label>
                        <input type="number" readonly class="form-control" v-model="companyId" placeholder="Enter Company Id" required>
                    </div>

                    <div class="tally_status">
                        <label>Select Module </label>
                        <select class="form-control" v-model="module" required v-on:change="moduleChange()">
        <option value="">Select Module Name</option>
        <option value="3">Invoice Module</option>
        <option value="4">Purchase Module</option>
        <option value="5">Expense Module</option>
        <option value="1">Credit Note Module</option>
        <option value="2">Debit Note Module</option>
        <option value="6">Banking Module</option>
    </select>
                    </div>

                    <div class="tally_status"  v-if="isbanklist">
                        <label>Select Bank Account</label>
                        <input style="border: 1px solid gray; padding: 7px;border-radius: 5px;" 
 list="account_list"  v-model="account_no"  placeholder="Select ACCOUNT NUMBER*" 
required="">
<datalist id="account_list" >
    <div v-for="(accounts,index) in userAccountList"  :key="index">
<option   >
    {{accounts.account_no}}</option>
</div>
</datalist>
                    </div>

                    <div class="tally_status">
                        <label>Start Date</label>
                        <datepicker v-model="date1"  inputFormat="dd-MM-yyyy" id="startdate" placeholder="Select Date" class="form-contol" > </datepicker>
                    </div>

                    <div class="tally_status">
                        <label>End Date</label>
                        <datepicker v-model="date2" inputFormat="dd-MM-yyyy" id="enddate" placeholder="Select Date" class="form-contol" > </datepicker>
                    </div>

<div class="tally_status">
<button type="submit" class="btn btn-success account_submit">Submit</button>
    </div>
                </form>
            </div>
       

            <div class="card_box" @click="disconnect_tally">
                <p>Disconnect Tally Sync </p>
                 <img src="https://instabill.co/public/ca_images/wifi.png" class="img2" style="width: auto;" />
         
                </div>
            <div class="clear"></div>
            <div class="steps">
                <h3 class="tally">STEPS TO LINK TALLY WITH INSTABILL</h3>
    
                <div class="steps_a">
                    <h3><b>STEP 1 –</b> Click on the Tally AddOn Button and Download the TCP file.</h3>
                    <img src="https://instabill.co/public/ca_images/step4.png" class="img2" style="width: auto;" />
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 2 –</b> Save the TCP file in Desktop <div class="tooltip">i
                        <span class="tooltiptext">do not delete this file, otherwise integration won’t work.</span>
                    </div></h3>
                    
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 3 –</b> Open Tally and press <span>F1</span> button from keyboard.</h3>
                    <img src="https://instabill.co/public/ca_images/step6.png" class="img2" style="width: auto;" />
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 4 –</b> Click on <span>TDL s & AddOns</span></h3>
                    <img src="https://instabill.co/public/ca_images/step7.png" class="img2" style="width: auto;" />
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 5 –</b> Press <span>F4</span> key from keyboard or go to <span>Manage Local TDLs</span></h3>
                    <img src="https://instabill.co/public/ca_images/step8.png" class="img2" style="width: auto;" />
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 6 –</b> Type <span>Yes</span> => Load selected TDL files on Startup and then <span>SELECT</span> TCP AddOn file path.</h3>
                    <img src="https://instabill.co/public/ca_images/step6a.png" class="img2" style="width: auto;" />
                    <img src="https://instabill.co/public/ca_images/step6b.png" class="img2 aaa" style="width: auto;" />
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 7 –</b> Click on <span>F3 key</span> from the Gateway of Tally to go Company Alteration. </h3>
                    <img src="https://instabill.co/public/ca_images/step10.png" class="img2" style="width: 100%;" />
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 8 –</b> Enable <span>Instabill Integration.</span></h3>
    
                    <img src="https://instabill.co/public/ca_images/step8a.png" class="img2" style="width: auto;" />
                </div>
    
                <div class="steps_a ppp">
                    <h3><b>STEP 9 –</b> Enter <span>URL</span> <a href="https://instabill.co">https://instabill.co</a> and Fill the <span>Username and Password.</span> <div class="tooltip tooltip_2">How To Get Username and Password?
                        <span class="tooltiptext"><img src="https://instabill.co/public/images/step9b.png" class="img2 aaa" style="width: auto;" /></span>
                    </div></h3>
                    <img src="https://instabill.co/public/ca_images/step9a.png" class="img2" style="width: auto;" />
                    <div class="clear"></div>
                    
    
                    <div class="clear"></div>
                    
                </div>
    
                <div class="steps_a">
                    <h3><b>STEP 10 –</b> Go to Gateway of Tally => Instabill Integration => Set Up</h3>
                    <img src="https://instabill.co/public/ca_images/step10a.png" class="img2" style="width: auto;" />
                    <h4 class="comp">NOW YOU ARE ALL SET…!!!</h4>
                    <h3>In case you feel trouble in Tally Integration. Take Tally Integration Support, <a href="https://instabill.freshdesk.com/support/tickets/new" target="_blank">Click here to raise ticket.</a></h3>
    
                </div>
    
                <h3 class="tally">Learn How to Auto-Sync Tally Data</h3>
                <div class="steps_a ppp">
                    <h3>Enable <span>Scheduler </span>and <span>Configure </span>the scheduler. Scheduler enabling will help you auto-sync your instabill data into tally on a periodic basis.</h3>
                    <h3><span>ENABLE INTEGRATION AT COMPANY LOAD =></span> When you <span>OPEN </span>tally then your data will be auto sync every time.</h3>
                    <h3><span>ENABLE INTEGRATION AT COMPANY CLOSING =></span> When you <span>CLOSE </span>tally then your data will be auto sync every time.</h3>
                    <h3><span>TIME FREQUENCY BASIS =></span> You can set the <span>TIME INTERVAL</span> to sync the Instabill data automatically. Minimum time is 30 minutes. (In every 30 minutes, Instabill syncs data in Tally automatically.)</h3>
                    <h3><span>SCHEDULER BASIS =></span> You can enter a <span>SPECIFIC TIME,</span> at that time your Instabill data will sync automatically on daily basis.</h3>
                    <img src="https://instabill.co/public/ca_images/step_p.png" class="img2 aaa" style="width: auto;" />
                    <img src="https://instabill.co/public/ca_images/step_pa.png" class="img2 aaa" style="width: auto;" />
                    <h3>In case you feel trouble in Tally Integration. Take Tally Integration Support, <a href="https://instabill.freshdesk.com/support/tickets/new" target="_blank">Click here to raise ticket.</a></h3>
    
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
        import datepicker from 'vue3-datepicker';
    import {
        postApi
    } from "../assets/js/helper.js";
    import CommonHeader from './CommonHeader.vue';
    
    export default {
        name: 'TallyIntegration',
        components: {
            datepicker,
            CommonHeader,
        },
        props: ["user_id", "company_id"],
        created() {
            this.getTallyInfo();
            this.getAccountList();
           window.scrollTo(0, 0);
        },
        data() {
            return {
                date1: new Date(new Date().getFullYear(),new Date().getMonth(),1),
                date2: new Date(new Date().getFullYear(),new Date().getMonth()+1,0),
                isModalVisible: false,
                isbanklist:false,
                username: "",
                tally_username:"",
                tally_integration_password: "",
                disconnect: false,
                connect: false,
                status: "",
                message: false,
                alertMessage: "",
                alertClass: "",
                date: null,
                userAccountList:null,
                userId:this.user_id,
                companyId:this.company_id,
                module:"",
                account_no:""
            }
        },
        methods: {
            showModal() {
                this.isModalVisible = true;
            },
            closeModal() {
                this.isModalVisible = false;
            },
            async getTallyInfo() {
                const response = await postApi(
                    "/getTallyInfo", {
                        company_id: this.company_id,
                    }, {}
                );
                try {
                    if (response.errorCode == "0") {
    
                        this.username = response.data.username;
                        this.tally_username=response.data.tally_username;
                        this.tally_integration_password = response.data.tally_integration_password;
                        if (response.data.status == "0") {
                            this.disconnect = true;
                            this.connect = false;
                        } else {
                            this.connect = true;
                            this.disconnect = false;
                        }
    this.status = response.data.status;
    if(response.data.tally_start_date!="" && response.data.tally_start_date!=null) {
 this.date=new Date(response.data.tally_start_date);
    }
    else
    {
 this.date=new Date(new Date().getFullYear(),new Date().getMonth(),1);
    }

                        return false;
                    }
                } catch (err) {
                    this.alertMessage = err;
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
    
                }
    
            },
            async disconnect_tally() {
                // alert(this.status);
                if (this.status == "0") {
                    this.alertMessage = "Account Allready Disconnected";
                    this.alertClass = "error_msg";
                    this.message = true;
                    setTimeout(() => this.message = false, 3000);
    
                    // alert("Tally already non connected this account");
                } else {
    
                var conf=confirm("Are you sure you want to disconnected tally");
                    if(conf)
                    {
                    this.disconnect = true;
                    this.connect = false;
                    this.status == "0";
                    this.alertMessage = "Tally Disconnected Successfully";
                    this.alertClass = "success";
                    this.message = true;
    
                    setTimeout(() => this.message = false, 3000);
    
                    const response = await postApi(
                        "/disconnectTallySync", {
                            company_id: this.company_id,
                        }, {}
                    );
                    try {
                        if (response.errorCode == "0") {
                            this.alertMessage = "Tally Sync Disconnected";
                        this.alertClass = "error_msg";
                        this.message = true;
                        setTimeout(() => this.message = false, 3000);
                        }
                    } catch (err) {
                        this.alertMessage = err;
                        this.alertClass = "error_msg";
                        this.message = true;
                        setTimeout(() => this.message = false, 3000);
                    }
                }
    
            }
        },
       async updateDate(){

            const response = await postApi(
                        "/updateTallyDate", {
                            company_id: this.company_id,
                            date:this.date
                             }, {}
                    );
                    try {
                        if (response.errorCode == "0") {
                            this.alertMessage ="Date Update Successfully" ;
                        this.alertClass = "error_msg";
                        this.message = true;
                        setTimeout(() => this.message = false, 3000);

                        }
                    } catch (err) {
                        this.alertMessage = err;
                        this.alertClass = "error_msg";
                        this.message = true;
                        setTimeout(() => this.message = false, 3000);
                    }

        },

        async getAccountList() {
     const response = await postApi(
            "/get_bank_account_lists",
            {
              user_id:this.user_id,  
              company_id: this.companyId,
            // user_id:37,  
            //   company_id: 6952,
            },
            {}
          );
          try {
            if (response.errorCode == "0") {
              console.log(response.data);
           this.userAccountList=response.data;
    
           return false;
            } 
          } catch (err) {
console.log();
          }
     }, 

    async moduleChange(){
    if(this.module == "6")
    {
        this.isbanklist = true;
    }
    else{
        this.isbanklist = false;
    }
     },

     async tally_status_update(){

        
        const response = await postApi(
            "/tally_import_status_update",
            {
              user_id:this.user_id,  
              company_id: this.companyId,
            //   user_id:37,  
            //   company_id: 6952,
              account_no:this.account_no,
              module:this.module,
              start_date:this.date1,
              end_date:this.date2
            },
            {}  
          );
          try {
            if (response) {
                this.isbanklist = false;
                this.isModalVisible = false;
                this.alertMessage = "Tally Status Updated Successfully";
                    this.alertClass = "success";
                    this.message = true;
    
                    setTimeout(() => this.message = false, 3000);
           return false;
            } 
          } catch (err) {
console.log();
          }
     },
     performEdit() {
            document.getElementById('tally_username').removeAttribute('readonly');
            document.getElementById("tally_username").focus();
},
async tally_username_save(){

    const response = await postApi(
            "/tally_username_update",
            {
              user_id:this.user_id,  
              company_id: this.companyId,
              tally_username: this.tally_username
            },
            {}  
          );
          try {
            if (response) {
               
                this.alertMessage = "Tally Username Updated Successfully";
                    this.alertClass = "success";
                    this.message = true;
    
                    setTimeout(() => this.message = false, 3000);
           return false;
            } 
          } catch (err) {
console.log();
          }
}

    }
}
    </script>
    
    <style scoped>
    @import '../assets/style.css';

    .tooltip_2 .tooltiptext {
        right: 0!important;
        left: auto!important;
        width: 600px!important;top: 31px;
    }
    .tooltip_2 .tooltiptext img {
        width: 100%!important;
    }
    .tooltip {
        position: relative;
        display: inline-block;
        border-bottom: 1px dotted black;
    }
    
    h4.comp {
        text-align: center;
        margin-top: 4%;
    }
    
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 220px;
        background-color: #333;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
        position: absolute;
        z-index: 1;
        line-height: 18px;
        font-size: 13px;
        left: 107%;
    }
    .tooltip_2 {
        width: auto!important;
        border-radius: 5px!important;
        padding: 7px 1%!important;
        height: auto!important;
    }
    .tooltip {
        opacity: 1;
        background: #fb8f29;
        width: 15px;
        height: 15px;
        color: #fff;
        text-align: center;
        line-height: 13px;
        border-radius: 50px;
        cursor: pointer;
    }
    
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    
    .steps_a {
        margin-bottom: 6%;
        margin-top: 5%;
    }
    
    .aaa {
        margin-top: 2%;
    }
    
    .ppp img {
        margin-bottom: 1%;
    }
    
    .steps h2 {
        font-size: 20px;
        color: #000;
        font-weight: 400;
    }
    
    .steps_a h3 {
        font-size: 18px;
        margin-top: 3%;
        color: #333;
    }
    
    .steps_a h3 b {
        color: #fb8f29;
        font-size: 21px;
    }
    input#tally_username:focus {
    outline-color: #fda111;
    border: 1px solid #ddd;
}
    .zoom-in-zoom-out {
        width: 10px;
        height: 10px;
        background: #61ae61;
        -webkit-animation: zoom-in-zoom-out 2s ease-out infinite;
        animation: zoom-in-zoom-out 2s ease-out infinite;
        overflow: hidden;
        border-radius: 50px;
        display: inline-block;
        margin-left: 7%;
    }
    
    .steps_a h3 span {
        font-weight: 600 !important;
        color: #000;
    }
    
    @keyframes zoom-in-zoom-out {
        0% {
            transform: scale(1, 1);
        }
    
        50% {
            transform: scale(1.2, 1.2);
        }
    
        100% {
            transform: scale(1, 1);
        }
    }
    
    .card_box i {
        font-size: 33px;
        color: #fb8f29;
    }
    
    .card_box {
        cursor: pointer;
        color: #000;
        text-align: center;
        width: 30%;
        float: left;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 10px;
        padding: 3%;
        margin: 3%;
        background: white;
        height: 159px;
        margin-left: 0;
        font-size: 20px;
        margin-top: 3%;
    }
    
    .boxes input {
        margin-left: 3%;
        font-size: 13px;
    }
    
    .boxes label {
        font-size: 15px;
    }
    
    .top_h {
        position: relative;
        background: #e8f0fe;
        padding: 1%;
        border-radius: 5px;
        width: 98%;
        margin-top: 3%;
    }
    
    .connected {
        position: absolute;
        right: 2%;
        width: 13%;
        color: #ec7200;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        padding: 3px 1%;
        border-radius: 16px;
        background: #fff;
        margin-top: 2%;
        cursor: pointer;
        transition: 0.5s
    }
    
    .connected:hover {
        background: linear-gradient(85deg, #f66a59, #ffae00);
        color: #fff
    }
    
    .connected:hover .zoom-in-zoom-out {
        background: #fff;
    }
    
    .left_a {
        width: 50%;
        float: left;
    }
    
    button.back_user {
        background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
        border-color: #fd9d16;
        border-radius: 5px !important;
        margin-right: 1%;
        color: #fff;
        padding: 5px 0%;
        margin-left: 37%;
        transition: 1s;
        float: right;
        margin-right: 2%;
    }
    
    h3.tally {
        font-size: 20px;
        color: #fb8f29;
        border-bottom: 1px solid #fb8f29;
        padding-bottom: 1%;
        display: inline;
    }
    
    .clear {
        clear: both
    }
    
    .disconnect {
        position: absolute;
    right: 2%;
    width: 15%;
    color: #ec7200;
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    padding: 3px 1%;
    border-radius: 16px;
    background: #fff;
    margin-top: 25px;
    cursor: pointer;
    transition: 0.5s;
    }
    
    .disconnect:hover {
        background: linear-gradient(85deg, #f66a59, #ffae00);
        color: #fff
    }
    
    .disconnect:hover .zoom-in-zoom-out {
        background: #fff;
    }
    
    .success {
        position: fixed;
        top: 0%;
        z-index: 999;
        width: 20%;
        padding: 1%;
        background: #fff;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 5px;
        right: 0;
        border-left: 4px solid #ffae00;
        transition: 1s;
    }
    .date_sect .v3dp__datepicker {
    width: 100%;
}.date_sect input {
    border: 0;
}
.date_fil {
    display: flex;
    align-items: center;    background: #fff;
    justify-content: center;
}

.date_fil .v3dp__datepicker input {
    width: 80%;
}

.date_fil .v3dp__datepicker {
    width: 100%!important;
}
.date_sect .v3dp__datepicker {
    width: 65%;    margin: 0;
    border: 1px solid #ccc;
}
.v3dp__datepicker input {
    width: 100%;
    border: 0px solid #ddd!important;
    padding: 4%;
    border-radius: 5px;
    text-align: left!important;
}
    .date_sect {
        position: absolute;
    right: 17%;
    width: 25%;
    padding: 0px 1%;
    border-radius: 0px;
    margin-top: 0px;
    cursor: pointer;
}
    p.txt {
        width: 91%;
        float: left;
        margin: 0;
    }
    
    .error_msg {
        position: fixed;
        top: 0%;
        z-index: 999;
        width: 20%;
        padding: 1%;
        background: #fff;
        box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
        border-radius: 5px;
        right: 0;
        border-left: 4px solid #db0014;
        transition: 1s;
        color: red;
    }
    
    .card_box:hover {
        box-shadow: none;
    }
    .sidebar_a {
    /* display: none; */
    float: right;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0px;
    background: white;
    /* color: white; */
    width: 410px;
    height: 100%;
    overflow-y: auto;
    transition: right 1s linear;
    /* padding: 30px; */
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    padding: 1% 2%;
}
.sidebar_a {
    /* display: none; */
    float: right;
    z-index: 9999;
    position: fixed;
    right: 0;
    top: 0px;
    background: white;
    /* color: white; */
    width: 410px;
    height: 100%;
    overflow-y: auto;
    transition: right 1s linear;
    /* padding: 30px; */
    border: 1px solid #d4d7dc;
    border-right: none;
    border-top: none;
    box-shadow: 0px 3px 21px 0px rgb(0 0 0 / 20%);
    padding: 1% 2%;
}
.top_h {
    padding: 2% 1% !important;
}
button.back_user {
    padding: 5px 0%;}
.sidebar_a h2 {
    width: 90%;
    float: left;
    color: #f97d40;
    font-size: 23px;
    text-transform: uppercase;
    margin-bottom: 20px;
    line-height: 35px;
    font-weight: 600!important;
    letter-spacing: 0px;
    width: 100%;
    position: relative;
}

.tally_status label {
    color: rgb(0, 0, 0);
    margin-bottom: 5px;
    font-weight: 600;
    width: 100%;
    text-transform: uppercase;
}
.v3dp__datepicker {
    width: 100%;
    text-align: left;
}

.v3dp__datepicker input {
    text-align: left;
}
.tally_status input {
    width: 100% !important;
    border: 1px solid gray;
    padding: 7px;
    border-radius: 5px!important;
    font-size: 1rem;
}
.tally_status {
    margin-top: 6%;
}
.tally_status select {
    width: 100%;
    border: 1px solid gray;
    padding: 7px;
    border-radius: 5px!important;
    font-size: 1rem;
    width: 100%;
}
button#setting_close1 {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    position: absolute;
    right: 4%;
}
input#tally_username {
    border: 1px solid #ddd;
    background: transparent;
    padding: 8px;
    border-radius: 5px !important;
}
    .date_sect label {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

    .btn_date {
        background: linear-gradient(to top left, #ffae00 0%, #f66a59 100%) !important;
    color: #fff;
    padding: 3px 4%;
    border: 0px;
    height: 38px;
    border-radius: 0px 5px 5px 0px;
}
    </style>
    
<template>
  <CommonHeader />
  <div id="main-content">
    <div id="page-container">
      <!--*************alert-->
      <div :class="alertClass" id="hidemessage" v-if="message">
        <p class="txt">{{ alertMessage }}</p>
        <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
      </div>
      <!--*************alert-->
      <h1 class="hed" style="    margin-bottom: 3%;">Client Details</h1>

      <!--**************************common**************-->
      <div class="profile" style="width:auto">
        <img :src="profile_img" class="profile_img" v-if="profile_img" />
        <span @click="editClientProfile"
          style="cursor: pointer; float: right; border: none; padding-right: 3%; margin-top: 5px; font-size: 18px; color: gray;">
          Edit
          <i class="fa fa-edit" aria-hidden="true"></i>
        </span>
        <router-link :to="{ name: 'ViewDetails', params: { user_id: user_id, company_id: company_id } }">
          <button class="back_user_1" style="padding: 5px 0%;">
            <i class="fa fa-angle-double-left" aria-hidden="true"></i>
            Back
          </button>
        </router-link>
      </div>
      <div class="clear"></div>
      <form v-on:submit.prevent="updateProfile" method="post" enctype="multipart/form-data">
        <div class="field">
          <label for="gstin_text">GSTIN</label><br>
          <input type="text" name="gstin_text" maxlength="15" v-model="gstin_text" readonly id="gstin_text"
            placeholder="GSTIN">
        </div>
        <div class="field">
          <label for="company_name">Company Name</label><br>
          <input type="text" required="" id="company_name" v-model="company_name" readonly placeholder="Company Name">
        </div>
        <div class="field">
          <label for="address">Address</label><br>
          <input type="text" id="address" v-model="address" readonly required="" placeholder="Address">
        </div>
        <div class="field">
          <label for="pincode">Pincode</label><br>
          <input type="text" id="pincode" v-model="pincode" readonly placeholder="Pincode">
        </div>
        <div class="field">
          <label for="email">GST Composite Scheme</label><br>
          <label class="switch" v-if="off">
            <span class="slider round "></span>
          </label>

          <label class="switch" v-if="on">
            <span class="slider round orange"></span>
          </label>

        </div>

        <div class="field">
          <label for="email">Email</label><br>
          <input type="email" id="email" v-model="email" readonly required="" placeholder="Email">
        </div>
        <div class="field">
          <label for="mobile">Mobile</label><br>
          <input type="number" id="mobile" v-model="mobile" readonly required="" placeholder="Mobile">

        </div>
        <div class="field">
          <label for="business">Nature of Business</label>
          <!-- <input type="text" id="business_type" v-model="business_type" readonly required="" placeholder="Nature of Business"> -->
          <select id="business_type" style="height: 49px;" v-model="business_type" required="">
            <option value="Manufacture">Manufacture</option>
            <option value="Wholeseller">Wholeseller</option>
            <option value="Trader / Retailer">Trader / Retailer</option>
            <option value="Pharma / Chemist">Pharma / Chemist</option>
            <option value="Travelling Agency">Travelling Agency</option>
            <option value="Service Provider">Service Provider</option>
            <option value="Others">Others</option>
        </select>
        </div>
        <div class="field">
          <label for="business">Type of Entity</label>
          <!-- <input type="text" id="entity_type" v-model="entity_type" readonly required="" placeholder="Nature of Business"> -->
          <select id="entity_type" style="height: 49px;" v-model="entity_type" required="">
            <option value="Sole Proprietor">Sole Proprietor</option>
            <option value="Partnership Firm">Partnership Firm</option>
            <option value="LLP">LLP</option>
            <option value="OPC">OPC</option>
            <option value="Private Limited Company">Private Limited Company</option>
            <option value="Public Limited Company">Public Limited Company</option>
            <option value="Society/Trust/NGO">Society/Trust/NGO</option>
            <option value="Others">Others</option>
        </select>
        </div>
        <div class="field">
          <label for="cin">CIN</label><br>
          <input type="text" id="cin" v-model="cin" readonly placeholder="CIN">
        </div>
        <div class="field">
          <label for="pan">PAN </label><br>
          <input type="text" id="pan_card" v-model="pan_card" readonly placeholder="PAN">
        </div>
        <div class="field">
          <label for="Website">Website </label><br>
          <input type="text" id="website" v-model="website" readonly placeholder="Website">
        </div>
        <div class="field">
          <label for="licence">License Title </label><br>
          <input type="text" id="license" v-model="license" readonly placeholder="Licence Title">
        </div>
        <div class="field">
          <label for="licence-No">License Number </label><br>
          <input type="text" id="license_number" v-model="license_number" readonly placeholder="Licence Number">
        </div>
        <div class="field">
          <button type="submit" id="clientProfileUpdate" class="btn bg-warning btn text-white w-100 d-none"
            style="font-size: 20px;font-weight: 600;">
            Update
          </button>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import CommonHeader from './CommonHeader.vue';
import { postApi } from "../assets/js/helper.js";
export default {
  name: 'UserProfile',
  components: {
    CommonHeader
  },
  data() {
    return {
      userid: '',
      companyId: this.company_id,
      gstin_text: "",
      company_name: "",
      adress: "",
      pincode: "",
      gst_composite_scheme: "",
      email: "",
      mobile: "",
      business_type: "",
      entity_type: "",
      cin: "",
      pan_card: "",
      website: "",
      license: "",
      license_number: "",
      profile_img: "",
      off: false,
      on: false,
      message: false,
      alertMessage: "",
      alertClass: "",
      // company_id: "",
    }
  },
  props: ["user_id", "company_id"],
  created() {
    this.getProfileData();
  },
  methods: {
    async getProfileData() {
      const response = await postApi(
        "/view_user_profile",
        {
          company_id: this.companyId,
        },
        {}
      );
      try {
        if (response.errorCode == "0") {
          console.log(response.data);
          if (response.data.gst_composite_scheme == "0") {
            this.off = "true"
          }
          else {
            this.on = "true"
          }
          this.gstin_text = response.data.gstin_text;
          this.company_name = response.data.company_name;
          this.address = response.data.address;
          this.pincode = response.data.pincode;
          this.gst_composite_scheme = response.data.gst_composite_scheme;
          this.email = response.data.email;
          this.mobile = response.data.mobile;
          this.business_type = response.data.business_type;
          this.entity_type = response.data.entity_type;
          this.cin = response.data.cin;
          this.pan_card = response.data.pan_card;
          this.website = response.data.website;
          this.license = response.data.license;
          this.license_number = response.data.license_number;
          this.profile_img = response.data.company_logo;
          this.userid = response.data.user_id;

          return false;
        }
      } catch (err) {
        alert(err);
      }
    },
    editClientProfile() {
      document.getElementById('gstin_text').removeAttribute('readonly');
      document.getElementById('company_name').removeAttribute('readonly');
      document.getElementById('address').removeAttribute('readonly');
      document.getElementById('pincode').removeAttribute('readonly');
      document.getElementById('business_type').removeAttribute('disabled');
      document.getElementById('entity_type').removeAttribute('disabled');
      document.getElementById('cin').removeAttribute('readonly');
      document.getElementById('pan_card').removeAttribute('readonly');
      document.getElementById('website').removeAttribute('readonly');
      document.getElementById('license').removeAttribute('readonly');
      document.getElementById('license_number').removeAttribute('readonly');
      document.getElementById("gstin_text").focus();
      const btn = document.getElementById("clientProfileUpdate");
      btn.classList.remove("d-none");
    },
    async updateProfile() {
      var company_id = this.company_id;
      var user_id = this.userid;
      var gstin_text = this.gstin_text;
      var company_name = this.company_name;
      var address = this.address;
      var pincode = this.pincode;
      var email = this.email;
      var mobile = this.mobile;
      var business_type = this.business_type;
      var entity_type = this.entity_type;
      var cin = this.cin;
      var pan_card = this.pan_card;
      var website = this.website;
      var license = this.license;
      var license_number = this.license_number;
      console.log();
      const response = await postApi(
        "/ca_client_update",
        {
          company_id: company_id,
          user_id: user_id,
          gstin_text: gstin_text,
          company_name: company_name,
          address: address,
          pincode: pincode,
          email: email,
          mobile: mobile,
          business_type: business_type,
          entity_type: entity_type,
          cin: cin,
          pan_card: pan_card,
          website: website,
          license: license,
          license_number: license_number,
          gst_composite_scheme: this.gst_composite_scheme,
        },
      );
      console.log(response);
      try {
        if (response.errorCode == "1") {
          this.alertMessage = response.errorMsg;
          this.alertClass = "error_msg";
          this.message = true;
          setTimeout(() => this.message = false, 3000);
        }else{
          this.alertMessage = response.errorMsg;
          this.alertClass = "error_msg";
          this.message = true;
          setTimeout(() => this.message = false, 3000);
        }
      } catch (err) {
          this.alertMessage = err;
          this.alertClass = "error_msg";
          this.message = true;
          setTimeout(() => this.message = false, 3000);
      }

    },
  }
}
</script>


<style scoped>
.orange {
  background: #ff8300;
}

.field input {
  background: #fff;
}
</style>
<template>
    <div :class="alertClass" id="hidemessage" v-if="message">
        <p class="txt">{{ alertMessage }}</p>
        <i class="fa fa-times close_msg" aria-hidden="true" @click="close_msg()"></i>
    </div>
    <div id="logo">
        <router-link to="/"><img src="https://instabill.co/public/instabillpro_logo.png"
                style="width:150px" /></router-link>
    </div>
    <div id="left-menu">
        <ul>

            <li class="has-sub">
                <router-link to="/Dashboard"><span>Dashboard</span></router-link>
            </li>
            <li>
                <router-link to="/MyProfile"><span>My Profile</span></router-link>
            </li>
            <li><router-link to="/ClientList"><span>Clients</span></router-link></li>
            <li v-if="packages_id != 1">
                <router-link to="/GstReturn"><span>GST Return Status</span></router-link>
            </li>
            <li v-else>
                <a @click="noPermission" role="button">
                    <span>GST Return Status</span>
                </a>
            </li>
            <li><router-link to="/DueDate"><span>Upcoming Due Date</span></router-link></li>
            <li><router-link to="/UserEarning"><span>Earning</span></router-link></li>
            <li><router-link to="/ChangePassword"><span>Change Password</span></router-link></li>
            <!-- <li><router-link to="/TaxPayment"><span>Pay Tax</span></router-link></li> -->
            <li><a href="https://instabill.freshdesk.com/support/tickets/new" target="_blank"><span>Report
                        Issue</span></a></li>
            <li><a v-on:click="logout" href=""><span>Logout</span></a></li>
        </ul>
    </div>
    <div id="header">
        <div class="header-left float-left">
            <i id="toggle-left-menu" class="ion-android-menu"></i>
        </div>

        <nav class="navbar navbar-expand-lg navbar-light">

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item  btna_n" style="padding: 8px!important;width: 134px;"><a
                            href="https://instabill.freshdesk.com/support/home" target="_blank"
                            style="font-weight: 500!important;font-size: 17px;color:#000">Support <i
                                class="fa fa-commenting-o" aria-hidden="true" style="margin-left: 5px;"></i></a></li>
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" style="    color: #000 !important;"
                            id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <b>{{ ca_name }} </b>
                            <img :src="logo" class="profile_pic">
                        </a>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="cursor:pointer">
                            <a href="#">{{ ca_name }}</a>
                            <a href="#"><strong>User Id</strong><br><span>INSTA0{{ ca_user_id }}</span></a>
                            <a href="#"><strong>Email Id</strong><br><span>{{ ca_email }}</span></a>
                            <a href="#"><strong>Mobile No.</strong><br><span>{{ ca_mobile }}</span></a>
                            <div class="dropdown-divider"></div>
                            <a target="_blank"
                                href="https://www.google.com/url?q=https://play.google.com/store/apps/details?id%3Dapp.instabillpro&amp;sa=D&amp;source=hangouts&amp;ust=1542012188164000&amp;usg=AFQjCNHKmvBPs39XZ4gg22Pe0dzrdKFpZQ">Download
                                Mobile App</a>
                            <router-link to="/changePassword">Change Password</router-link>
                            <a v-on:click="logout">Logout</a>
                        </div>
                    </li>

                </ul>

            </div>
        </nav>

    </div>
    <CurrentPlan :plan="modalVisible" @hide-modal="hideModal" />

</template>

<script>
import { postApi } from "../assets/js/helper.js";
import CurrentPlan from './CurrentPlan.vue';
export default {
    name: 'CommonHeader',
    components: {
        CurrentPlan, // Register the imported component here
    },
    data() {
        return {
            alertClass: "",
            messageg: false,
            ca_name: "",
            ca_email: "",
            ca_mobile: "",
            ca_user_id: "",
            packages_id: "",
            modalVisible: false,
            logo: "https://instabill.co/public/images/profile_av.png",
        }
    },
    created() {
        this.top_header_data();
    },
    methods: {
        logout() {
            event.preventDefault();
            localStorage.removeItem('admin_id')
            location.href = "/";
        },
        noPermission() {
            this.modalVisible = true; // Show modal when li is clicked
        },
        hideModal() {
            this.modalVisible = false; // Hide modal when "No Thanks" is clicked
        },
        close_msg() {
            this.message = false;
        },
        close_msgg() {
            this.messageg = false;
        },
        async top_header_data() {
            const response = await postApi(
                "/top_header_data",
                {
                    ca_id: localStorage.getItem("admin_id"),
                },
                {}
            );
            try {
                // console.log(response);
                if (response.errorCode == "0") {
                    this.ca_user_id = response.data.id;
                    this.ca_name = response.data.name;
                    this.ca_email = response.data.email;
                    this.ca_mobile = response.data.phone;
                    this.packages_id = response.data.packages_id;
                    // this.packages_id = 1;
                    if (response.data.logo != "") {
                        this.logo = "https://instabilldoc.s3.ap-south-1.amazonaws.com/profile_pic/" + response.data.logo;
                    } else {
                        this.logo = "https://instabill.co/public/images/profile_av.png";
                    }
                    return false;
                }
            } catch (err) {
                alert(err);
            }

        }

    }

}
</script>
<style scope>
nav.navbar.navbar-expand-lg.navbar-light {
    width: 25%;
    float: right;
    position: relative !important;
    padding-right: 0px !important;
}

img.profile_pic {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    margin-left: 11px;
}

div#navbarSupportedContent b {
    font-weight: 600;
}

div#navbarSupportedContent {
    height: 40px;
}

.dropdown-toggle::after {
    display: none
}
</style>